
import React,{forwardRef,useEffect,useState, useRef} from 'react';
import {getContentPath} from 'system/AssetManager.js';
import ThreeVR from './threevr.js';
import { ReactCompareSlider, ReactCompareSliderImage, styleFitContainer } from 'react-compare-slider';
import Globals from 'system/Globals.js';
import gsap,{Power2} from 'gsap'
import mstyles from '../modules.module.css';
import styles from '../../narrative.module.scss'
import galleryStyles from './gallery.module.css';
import LoaderWheel from 'system/components/LoaderWheel.js';
import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import {ReactComponent as ChevronThinButton} from 'assets/icons/caret_right.svg'
import {ReactComponent as TrayClose} from 'assets/icons/tray_close.svg'
import {ReactComponent as PlayButton} from 'assets/icons/play.svg'
import {ReactComponent as PauseButton} from 'assets/icons/pause.svg'
import {ReactComponent as VRButton} from 'assets/icons/vr_white.svg'
import {ReactComponent as Burger} from 'assets/icons/burger.svg';
import {ReactComponent as CaretLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as CaretDown} from 'assets/icons/caret_down.svg'
import {ReactComponent as Return} from 'assets/icons/return.svg'
import {ReactComponent as Home} from 'assets/icons/expo-home-icon.svg'
import {ReactComponent as Apps} from 'assets/icons/apps.svg'
import ToolsMenu from 'system/components/ToolsMenu.js'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { ShaderChunk } from 'three';

const GalleryModule = forwardRef((props, ref)=>{

    const refServerAppListener =  useRef();
    const refActiveFile = useRef();
    const refGalleryMain = useRef();
    const refThumbnails = useRef();
    const refNavOpen = useRef(true);
    const refIsPlaying = useRef(false);
    const refBtnContainerBkg = useRef();

    const [format,setFormat] = useState(null)
    const [loaded,setLoaded] = useState(false);
    const [status,setStatus] = useState('initializing');
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeCategoryHeading, setActiveCategoryHeading] = useState('All');
    const [activeFile, setActiveFile] = useState(null);
    const [navOpen, setNavOpen] = useState(true);
    const [thumbnailTransition, setThumbnailTransition] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState('#6a6a6a');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [inTransition, setInTransition] = useState(false);
    const [showSwiper, setShowSwiper] = useState(false);
    const [activeFileType, setActiveFileType] = useState(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [slideNum,setSlideNum] = useState(null);
    const [activeVR, setActiveVR] = useState(null);
    const [noSwiping, setNoSwiping] = useState(false);
    const [loadVR, setLoadVR] = useState(false);
    const [vrFile, setVRFile] = useState(null);
    const [showLanding, setShowLanding] = useState(true);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [showSliderNav,setShowSliderNav] = useState(true);
    const [hasFileCats, setHasFileCats] = useState(false);
    const [showDefault, setShowDefault] = useState(null);
    const [vrZoom, setVrZoom] = useState(false);
    const [remoteMouse, setRemoteMouse] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [showModules, setShowModules] = useState(false);
    const [backPageIndex, setBackPageIndex] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    function removeParamUrl(name){
        let params = new URLSearchParams(props.history.location.search);
        params.delete(name)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
    }

    function onClickClose(evt){
        props.closeModule()
        setLoadVR(false);
        removeParamUrl('file');
        removeParamUrl('category');
       
    }   

    // function startGalleryTimeout(){
    //     setDimControls(false);
    //     var dimTimeout = setTimeout(() => {
    //         setDimControls(true);
    //     }, 1000 * 1);
    //     setGalleryTimeout(dimTimeout);
    // }
    // function setActiveFileId(elem){
    //     var elem = document.getElementsByClassName('swiper-slide-active')[0];
    //     var elemId = elem.getElementsByTagName('div')[0].dataset.fileid;
    //     if (elemId) {
    //         setActiveFile(elemId);
    //         console.log('elemId', elemId);
    //     }
    // }


    function convertColors(elemId) {
        var elem = document.getElementById(elemId);
        if (elem) {
            const style = getComputedStyle(elem)
            const backgroundColor = style.backgroundColor
            var newBackgroundColor = backgroundColor.replace(')', ', 0.85)').replace('rgb', 'rgba');
            elem.style.backgroundColor = newBackgroundColor;
        }
        
    }

    useEffect(()=>{ 
        setMenuOpen(false);
        removeParamUrl('slide')
        console.log("gallery module starting", props);
        ref.current.style.display='block';
        gsap.from(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
        }});
        refIsPlaying.current = false;

        (async ()=>{
            try { 
                setStatus('Loading JSON')
                if (props.model.files) {
                    console.log('Gallery JSON', props.model)

                    if (props.model.formats) {
                  
                        setFormat( Globals.instance().getFormat(props.model.formats))
                        console.log('setting gallery format', Globals.instance().getFormat(props.model.formats), props.model.formats)
                   
                    }
                    


                    setFiles(props.model.files);
                    if (props.model.files.length > 0) {
                        setActiveFile(props.model.files[0].id)
                    }
                    
                    if (props.model.backgroundColor) {
                        setBackgroundColor(props.model.backgroundColor);
                    }
                    if (props.model.filecategories) {
                        setHasFileCats(true);
                        setCategories(props.model.filecategories);
                    } else {
                        setCategories(props.model.categories);
                    }
                    
                }
                setStatus('Setting Gallery')
           
            }catch(err){
          
            }
        })();

        console.log('gallery props', props)


    },[]);
    


    useEffect(() => {
        if (props && showModules) {
            let elems1 = document.querySelectorAll('.stop1')
            elems1.forEach(element => {
              if (props.theme.css && props.theme.css['module-stop-0']) {
                element.setAttribute('stop-color', props.theme.css['module-stop-0'])
              }
            });
            let elems2 = document.querySelectorAll('.stop2')
            elems2.forEach(element => {
              if (props.theme.css && props.theme.css['module-stop-1']) {
                element.setAttribute('stop-color', props.theme.css['module-stop-1'])
                console.log('element2', element)
              }
            });

       
    
        }
    }, [props, showModules])

    function onVrZoomIn() {
        console.log('onVrZoomIn');
        setVrZoom(-20)
    }

    function onVrZoomOut() {
        console.log('onVrZoomOut');
        setVrZoom(20)
    }

    function onVidBack(activeFileId) {
        console.log('onVidBack', activeFileId);
        if (activeFileId) {
            var elem = document.getElementById("file_" + activeFileId);
            elem.currentTime = elem.currentTime - 15;
        }   
       
    }

    function onVidForward(activeFileId) {
        console.log('onVidForward', activeFileId);
        if (activeFileId) {
            var elem = document.getElementById("file_" + activeFileId);
            elem.currentTime = elem.currentTime + 15;
        }   
        
    }

    function onVidPause() {
        console.log('onVidPause');
        let videos = document.getElementsByTagName('video');
        let vidArray = [].slice.call(videos);
        for (let i = 0; i < vidArray.length; i++) {
            vidArray[i].pause();
            refIsPlaying.current = false;
            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) {
           
                props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-command?command=vidplay']);
                console.log('module-gallery-command?command=vidpause')
            }
        }
    }

    function onVidPlay(activeFileId) {
        

        var elem = document.getElementById("file_" + activeFileId);

        console.log('onVidPlay elem', elem)
        
        if (elem) {
            setActiveFileType('video');
            
            setIsPlaying(isPlaying => !isPlaying);

            if (!refIsPlaying.current) {
                console.log('onVidPlay: Play', activeFileId);
                elem.play();
                refIsPlaying.current = true;
            } else {
                console.log('onVidPlay: Pause', activeFileId);
                elem.pause();
                refIsPlaying.current = false;
            }
         
        }
        
    }

    function onClickCloseVR() {
        setActiveVR(null);
        setVRFile(null);
        setLoadVR(false);

        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) {
           
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-command?command=closeVr']);
            console.log('module-gallery-command?command=closeVr')
        }
        
    }

    useEffect(() => {
        if (props.model.files) {
          
            setFiles(props.model.files);
            if (props.model.backgroundColor) {
                setBackgroundColor(props.model.backgroundColor);
            }
            if (props.model.background_image) {
                setBackgroundImage(props.model.background_image)
            }
            
            if (props.model.filecategories) {
                setHasFileCats(true);
                setCategories(props.model.filecategories);
            } else {
                setCategories(props.model.categories);
            }
            
      
        }
    }, [props.model.files])

    useEffect(()=>{ 
        
        if (files.length > 0){
            setLoaded(true) 
            // setActiveFile(files[0].id);
            console.log('files', files, files[0].id, props.socket, props.socketPrefs, props.index);
            
            if (props.socket) {
                props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'select-module?module=' + props.index]);
                console.log('page handsake sent', 'select-module?module=' + props.index);
            }
           

        }
    },[files, props.socket, props.socketPrefs, props.index]);

    // useEffect(() => {
    //     if (props.socketPrefs && props.socketPrefs.mode && props.socketPrefs.mode === "isReceive") {
    //         setShowLanding(true)
    //     }
    // }, [props.socketPrefs])

    useEffect(() => {
        console.log('showDefault', showDefault)
    }, [showDefault])

    function calcLeftNavHeight(){
        let hElem = document.getElementById('rightNav');
        if (hElem) {
            let h = hElem.offsetHeight;
            let result = h - 400 + "px";
            console.log('result', result)
            let elem = document.getElementById('galleryThumbnails')
            elem.style.maxHeight = result
        }
        
    }


    function calcSubNavHeight(){
        let h = document.getElementById('rightNav').offsetHeight;
        let result = h - 400 + "px";
        console.log('result', result)
        let elem = document.getElementById('gallerySubNav')
        elem.style.maxHeight = result
    }


    useEffect(()=>{ 
        // console.log('GALLERY')
        if (swiperInstance && loaded) {
            const swiper  = swiperInstance;
            swiper.update();
            processParams();
            var videos = document.getElementsByTagName('video');
            var vidArray = [].slice.call(videos);
                                           
            // for (let i = 0; i < vidArray.length; i++) {
            //     // vidArray[i].currentTime = vidArray[i].currentTime + 1;
            //     vidArray[i].currentTime = 0
            // }


        
            calcLeftNavHeight();

            window.addEventListener('resize', calcLeftNavHeight)

        } 

        return () => {
            window.removeEventListener('resize', calcLeftNavHeight)
        }
        
    },[loaded, swiperInstance]);

    useEffect(() => {
        if (menuOpen) {
            calcSubNavHeight();
            window.addEventListener('resize', calcSubNavHeight)
        } else {
            window.removeEventListener('resize', calcSubNavHeight)
        }
    }, [menuOpen])

    useEffect(() => {
        function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
          }
          
        if (!showLanding && props.theme && props.theme.css && props.theme.css['primary-background']) {
            // refBtnContainerBkg.current.style.background = "linear-gradient(0deg, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 0%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 30%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 0) 100%)";
            try {
                refBtnContainerBkg.current.style.background = "linear-gradient(0deg, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 0%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 80%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 0) 100%)";
                console.log('bkg update',hexToRgb(props.theme.css['primary-background']))
            } catch (error) {
                console.error('refBtnContainerBkg style error')
            }
            
           
           
        }
    }, [props.theme, showLanding])


    useEffect(()=>{ 
        if (loaded) {
            console.log('activeFile', activeFile);
            refActiveFile.current = activeFile;
            var file = files.filter(item => (item.id === activeFile))[0];
            
            if (file) {
                setActiveFileType(file.fileType);
                console.log('file.fileType set', file.fileType, file);
            } else {
                setActiveFileType('image');
            }
            
    
            if (Globals.instance().serverApp || props.socketPrefs.mode === "isReceive") {
                setShowDefault(true);
         
                window.removeEventListener('server-event', refServerAppListener.current)
                const serverAppListener = (evt)=>{
                    let detail = evt.detail
                    if(!detail)return
                    if(detail.event==='module-gallery-select-file'){ 
                        console.log('module-gallery-select-file received', detail.command)
                        var file = files.filter(item => (item.id === parseInt(detail.command)))[0];
                        console.log('module-gallery-select-file', detail.command, file, files)
                        
                        handleThumbnailClick(file.id, file.fileType, file.file, file.categories);
                       
                        if ((props.socketPrefs.mode === "isReceive") && (file.filecategory)) {
                        
                            var cat = categories.find(item => ((item.id === parseInt(file.filecategory))));  
                            if (cat) {
                                setActiveCategoryHeading(cat.short_name);
                                setActiveCategory(cat.id);
                                console.log('isReceive category', cat.id)
                            }
                           
                        }

                        refActiveFile.current = file.id;

                        if (props.socketPrefs.mode !== "isReceive") {
                            setShowLanding(false);

                            setShowDefault(false);
                            console.log('setShowDefault(false)')
                        }
                        
                        if (props.socket) {
                            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-gallery-select-file?command=' + detail.command]);
                            console.log('page handsake sent', 'module-gallery-select-file?command=' + detail.command);
                        }
                        
                
                    }
                    if(detail.event==='module-gallery-select-vrfile'){ 
                        console.log('module-gallery-select-vrfile received', detail.command)
  
                        
                        handleStartVR(Number(detail.command));
                        if (props.socket) {
                            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-gallery-select-vrfile?command=' + detail.command]);
                            console.log('page handsake sent', 'module-gallery-select-vrfile?command=' + detail.command);
                        }
                
                    }
                    if(detail.event==='module-gallery-select-category'){   
                        setActiveCategory(parseInt(detail.command));
                        setShowLanding(false);
                        console.log('module-gallery-select-category recd', parseInt(detail.command))
                        if (props.socket) {
                            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-gallery-select-category?command=' + detail.command]);
                            console.log('page handsake sent', 'module-gallery-select-category?command=' + detail.command);
                        }
                    }
                    if(detail.event==='module-gallery-command'){ 
                        
                       switch(detail.command) {
                            case "prev":
                                setTimeout(() => {
                                    handleGalleryPrev(refActiveFile.current);
                                }, 300);
                                
                                break;
                            case "next":
                                setTimeout(() => {
                                    handleGalleryNext(refActiveFile.current);
                                }, 300);
                                
                                break;
                            case "vrzoomin":
                                onVrZoomIn();
                                break;
                            case "vrzoomout":
                                onVrZoomOut();
                                break;
                            case "vidback":
                                onVidBack(refActiveFile.current);
                                break; 
                            case "vidforward":
                                onVidForward(refActiveFile.current);
                                break;
                            case "vidpause":
                                onVidPause();
                                if (props.socket) {
                                    props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-gallery-command?command=vidpause']);
                                }
                                break;
                            case "vidplay":
                                refIsPlaying.current = false;
                                console.log('vidplay, refActiveFile.current', refActiveFile.current);
                                setTimeout(() => {
                                    onVidPlay(refActiveFile.current);  
                                }, 200);
                                
                                
                                if (props.socket) {
                                    props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-gallery-command?command=vidplay']);
                                }
                                // document.getElementById('file_' + refActiveFile.current).muted = false;
                                
                                break;
                            case "closeVr":
                                onClickCloseVR();
                                break;
                            case "toggleMenu":
                                onClickHide();
                                break;
                            default:
                                {}
                       }
                    }
                }
               
                refServerAppListener.current = serverAppListener
                window.addEventListener('server-event', refServerAppListener.current)
                
            }
        } 
        
    },[loaded]);
    
    useEffect(() => {
        if (activeFileType) {
            if (activeFileType === 'compare') {
                setNoSwiping(true);
                console.log('no swiping', activeFileType);
            } else {
                setNoSwiping(false);
                console.log('swiping', activeFileType);
            }
        }
    }, [activeFileType])
    
    useEffect(()=>{ 
        setTimeout(() => {
            setThumbnailTransition(false); 
            setShowThumbnails(true);
        }, 500);
    },[activeCategory, swiperInstance]);

    useEffect(() => {
        if (activeFile && loaded) {
            console.log('activeFile', activeFile)
            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) {
                
                props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-select-file?command=' + activeFile]);
                console.log('cast handleThumbnailClick', activeFile)
            }
        }
    }, [activeFile, loaded])

    useEffect(() => {
        if (swiperInstance) {
            if (noSwiping === true) {
                var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
                swiperElem.classList.add('swiper-no-swiping');
            } else {
                var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
                swiperElem.classList.remove('swiper-no-swiping');
            }
        }  
    }, [noSwiping])

    useEffect(() => {
        if (slideNum && files) {
            var sliderFiles = files.filter((file) => {
                if (activeCategory === 0) {
                    return file
                } else {
                    return (file.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            console.log('swiper change', sliderFiles[slideNum-1], sliderFiles, slideNum, activeCategory);
            if (sliderFiles[slideNum-1]) {
                let slideId = sliderFiles[slideNum-1].id;
                setActiveFile(slideId);
                refActiveFile.current = slideId;
                setActiveFileType(sliderFiles[slideNum-1].fileType)
            }
      
        }
    }, [slideNum])

    function processParams(){
        console.log("processParams started", props.history.location.search);

        let params = new URLSearchParams(props.history.location.search);
        let module = params.get('module');
        let moduleName = module.split('-');
        let file0 = params.get('file');
        let cat0 = params.get('category');
        let thumbs = params.get('thumbs');
        let back = params.get('back');

        if (back) {
            setBackPageIndex(back)
        } else {
            setBackPageIndex(false)
        }

        if ((file0) && (moduleName[0] === ("gallery"))){
           
            setShowLanding(false);
            if (cat0) {
                var activeCategory = parseInt(cat0);
                var cat = categories.filter(item => ((item.id === parseInt(cat0))))[0];  
                if (cat) {
                    setActiveCategoryHeading(cat.short_name);
                    setActiveCategory(cat.id);
                    console.log('category param', cat.id)
                }
            } else {
                var activeCategory = 0;
            }

            var sliderFiles = files.filter((file1) => {
                if (activeCategory === 0) {
                    return file1
                } else {
                    return (file1.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(parseInt(file0));
           
            setActiveFile(parseInt(file0));
   
            
            setSlideNum(index);
            setShowSwiper(true);
            const swiper = swiperInstance;
            swiper.update();
            swiper.slideTo(index, 0);

            setTimeout(() => {
                if (cat0) {
                    setShowDefault(false);
                }
                
             
            }, 200);
            
            console.log("processParams 0", index, file0, activeCategory, sliderFiles);
           

        } else {
            if (!Globals.instance().serverApp) {
                var file = files[0];
                if (moduleName[0] === ("gallery") && file) {
                    setActiveFile(file.id);
                    updateParamUrl("file", file.id);
    
                    console.log("processParams 1", index, file0, activeCategory, sliderFiles, file);
                }
            }
           
    
           
            
        }

        if (thumbs && (thumbs === "false")) {
            setNavOpen(false);
            refNavOpen.current = false;
            setShowSliderNav(false);
        } else {
            setNavOpen(true);
            refNavOpen.current = true;
            setShowSliderNav(true);
        }
        

        

    }

    function updateParamUrl(name, objIDs){
        let params = new URLSearchParams(props.history.location.search);
        params.set(name, objIDs)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
    }

    function handleMenu(){
        
        if (menuOpen) {
            gsap.to(refThumbnails.current, {opacity: 1, duration: 0.2});
            setMenuOpen(false);
        } else {
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0.2});
            setMenuOpen(true);
        }
        // setMenuOpen(prevCheck => !prevCheck);
    }


    function handleThumbnailClick(objID, fileType, filePath, categories){

        console.log('handleThumbnailClick', objID, fileType, filePath, categories)

        document.querySelectorAll('video').forEach(vid => vid.pause());
        
        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) {
           
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-select-file?command=' + objID]);
            console.log('cast handleThumbnailClick', objID)
        }


        setActiveFile(objID);
        let index = 0;
        let categoryId = 0;
        if (categories.length > 0) {
            categoryId = categories[0]
        }
        // console.log("objid", objID, fileType, filePath);
        let sliderFiles;
        if (Globals.instance().serverApp) {
            // sliderFiles = files.sort((a, b) => a.ordering - b.ordering);
            sliderFiles = files.filter((file) => {
                if (categoryId === 0) {
                    return file
                } else {
                    return (file.categories.includes(categoryId));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
        } else {
            sliderFiles = files.filter((file) => {
                if (categoryId === 0) {
                    return file
                } else {
                    return (file.categories.includes(categoryId));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
        }


        index = sliderFiles.map(function(x) {return x.id; }).indexOf(objID);

        if (activeVR) {
            var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
            swiperElem.classList.remove('swiper-no-swiping');
            setActiveVR(null);
        }

        if (fileType === 'compare') {
           setNoSwiping(true);
        } else {
            setNoSwiping(false);
        }

        console.log('thumbnail index', index)
        
        setSlideNum(index);
        setShowSwiper(true);
        const swiper = swiperInstance;
        swiper.update();
        swiper.slideTo(index, 0);

        updateParamUrl('file', objID)
       
    }

    function onClickHide() {
      
        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) { 
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-command?command=toggleMenu']);
            console.log('cast module-gallery-command?command=toggleMenu')
        } 
        console.log('refNavOpen', refNavOpen)
        // setNavOpen(navOpen => !navOpen)
        if (refNavOpen.current) {
            refNavOpen.current = false;
            setNavOpen(false)
        } else {
            refNavOpen.current = true;
            setNavOpen(true)
        }
        
    
    }

    function handleCategoryClick(objID, heading, anim) {
        console.log('handleCategoryClick', objID, heading);
        setShowLanding(false);
        setThumbnailTransition(true);
        setMenuOpen(false);
        removeParamUrl('file')

        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) { 
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-select-category?command=' + objID]);
            console.log('cast handleCategoryClick', objID)
        }

        if (!anim) {
            gsap.to(refGalleryMain.current, {opacity: 0, duration: 0});
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0});
        } else {
            gsap.to(refGalleryMain.current, {opacity: 0, duration: 0.3});
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0.3}, "-=0.3");
        } 
        // handleMenu();
        setTimeout(() => {
            setActiveCategory(objID);
            setActiveCategoryHeading(heading);
            updateParamUrl('category', objID);
            const swiper = swiperInstance;
            // setSlideNum(0);
            setShowSwiper(true);

            var sliderFiles = files.filter((file1) => { return (file1.categories.includes(objID)) })
            .sort((a, b) => a.ordering - b.ordering);

            
            if ((sliderFiles[0])&&(objID !== 0)) {
            setActiveFile(sliderFiles[0].id);
            setActiveFileType(sliderFiles[0].fileType)
            }
            
        //    console.log("handleCategoryClick", objID, sliderFiles)
            swiper.update();
            swiper.slideTo(0, 0);
            gsap.to(refGalleryMain.current, {opacity: 1, duration: 0.3});
            gsap.to(refThumbnails.current, {opacity: 1, duration: 0.3});

        }, 200);
        // if (objID !== activeCategory) {
            
        // }
    }

    function handleCategoryThumbnailClick(objID, heading) {
        handleCategoryClick(objID, heading, false);
    }

    function onSwiper(swiper){
        setSwiperInstance(swiper);
        let slideNumVal = 0
        if (slideNum) {
            slideNumVal = slideNum
        }
        swiper.slideTo(slideNumVal, 0);
        // console.log('swiper init');
        swiper.on('sliderMove', function() { 
            setInTransition(true);            
        });

        swiper.on('touchEnd', function() { 
            setInTransition(false); 
        });
   
        

      }

    function onSlideChange(swiper){

        setSlideNum(swiper.realIndex + 1);
        // setActiveFile(sliderFiles[swiper.realIndex + 1].id)
        
        swiper.on('slideChangeTransitionEnd', function() { 
            setInTransition(false);
            // setActiveFileId();

         

            
            var actives = document.querySelectorAll('.activeSlide > div');
            actives.forEach((active, i) => {
                // console.log('change', active.dataset)
                setActiveFileType(active.dataset.filetype);
               
            });
            // console.log('this slider', active)
        });
    
       
    }
    
    function handleGalleryNext(activeFileId){
        if (activeFileId) {
            var sliderFiles = files
            .filter((file) => {
                if (activeCategory === 0) {
                    return file
                } else {
                    return (file.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(activeFileId);

            var next = sliderFiles[index+1];
            if (next) {
                console.log('handleGalleryNext', activeFileId, sliderFiles, activeCategory, index, next);
                setActiveFile(next.id);
                refActiveFile.current = next.id;
                swiperInstance.slideNext(500);
               
            }
        }
        // console.log("handleGalleryNext", activeCategory, next)
    }
    
    function handleGalleryPrev(activeFileId){
        // document.querySelectorAll('video').forEach(vid => vid.pause());
        var sliderFiles = files
        .filter((file) => {
            if (activeCategory === 0) {
                return file
            } else {
                return (file.categories.includes(activeCategory));
            }
        })
        .sort((a, b) => a.ordering - b.ordering);
        var index = sliderFiles.map(function(x) {return x.id; }).indexOf(activeFileId);
        console.log(sliderFiles, activeFileId);
        if (index) {
           if (sliderFiles[index-1]) {
                var prev = sliderFiles[index-1].id;
                setActiveFile(prev);
                refActiveFile.current = prev;
                swiperInstance.slidePrev(500);
           }
            
        }
        
    }

    function handleFullScreen(){
        var elem = document.getElementById("file_" + activeFile);
        if (!props.socketPrefs.mode) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { 
                elem.msRequestFullscreen();
            } else if (elem.webkitEnterFullscreen) {
                elem.webkitEnterFullscreen(); 
            }
            // elem.muted = false;
            elem.currentTime=0
            elem.play();
            refIsPlaying.current = true;
            setIsPlaying(true);
          
            elem.addEventListener("fullscreenchange", function () {
                if (document.fullscreenElement) {
                } else {
                    elem.pause();
                    refIsPlaying.current = false;
                    setIsPlaying(false);
                }
                console.log('fullscreenchange', document.fullscreenElement, document)
            }, false);
            elem.addEventListener("mozfullscreenchange", function () {
                if (document.mozfullscreenchange) {
                } else {
                    elem.pause();
                    refIsPlaying.current = false;
                    setIsPlaying(false);
                }
            }, false);
            elem.addEventListener("webkitendfullscreen", function () {
                if (document.webkitendfullscreen) {
                } else {
                    elem.pause();
                    refIsPlaying.current = false;
                    setIsPlaying(false);
                }
                console.log('webkitendfullscreen', document.webkitendfullscreen, document)
            }, false);
            
        }
    
        if (props.socketPrefs.mode === "isBroadcast") {
            
            setIsPlaying(isPlaying => !isPlaying);

            document.getElementById("file_" + activeFile);
        
            if (isPlaying) {
                // elem.muted = true;
                elem.pause();
                refIsPlaying.current = false;

            } else {
                // elem.muted = false;
       
                elem.play();
                refIsPlaying.current = true;
            }

           
            
            
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-command?command=vidplay']);
            console.log('module-gallery-command?command=vidplay')
         
        }        

    }
    
    function handleStartVR(objID) {
        var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
        swiperElem.classList.add('swiper-no-swiping');
        setActiveVR(objID);
        let vrfileObj = files.filter(item => ((item.id === objID)))[0];
        setVRFile([vrfileObj.file, vrfileObj.vr_ref, vrfileObj.rotation, vrfileObj.start_pos, vrfileObj.end_pos]);
        setLoadVR(true);

        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && props.socket) {
           
            props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-select-vrfile?command=' + objID]);
            console.log('cast handleStartVR', objID)
        }
    }

    function onClickReturn(){
        if (backPageIndex) {
            if ((Globals.instance().ios && Globals.instance().useLocal) || Globals.instance().electron) {
                onClickClose()
            } else {
                let url = new URL(window.location.href)
                let search = "nav=closed&slide=" + backPageIndex

                let pathname = url.pathname;
                if (Globals.instance().ios && !Globals.instance().useLocal) {
                    pathname = '/narrative/' + props.model.narrative
                }
                props.history.push({pathname:pathname, search:'?'+ search})  
                // setShowModules(false) 
                console.log('onClickReturn', backPageIndex, props.model.narrative)
                props.onClickModule(-1, true, backPageIndex)
                // window.location.href = url.pathname + search
            }
            
        }
    }
   
    
    function handleComparePadding(fileId) {
        return 0
        
    }

    function onIconClick(index) {
        // console.log('onIconClick', index)
        setShowModules(false)
        props.onClickModule(index, true)
    }

    const formatStyle = format && user && user.letterbox ? {
        'background': 'transparent',
        'width': format.inset_width * 100 + "vw",
        'maxHeight': format.inset_height * 100 + 'vh',
        'left': format.inset_x * 100 + "vw",
        'top': format.inset_y * 100 + "vh",
        'aspectRatio': (format.inset_aspect_ratio ? format.inset_aspect_ratio.toString() : format.aspect_ratio),
        'position': 'relative'
    } : {}


    const formatSwiperStyle = format && user && user.letterbox ? {
        // 'top': format.inset_y * 100 + "vh",
        'height': 'auto',
        'aspectRatio': (format.inset_aspect_ratio ? format.inset_aspect_ratio.toString() : format.aspect_ratio),
        'width': '100%',
        'maxHeight': format.inset_height * 100 + 'vh',
        'position': 'relative'
    } : {
        'width': '100%',  
        'height': '100vh'
    }

    console.log('user.letterbox', user.letterbox)
    // console.log('format styles', formatStyle, formatSwiperStyle, format.inset_aspect_ratio)


    return (
        <div className={`fullscreen fcenter ${mstyles.moduleHolder} ${(vrFile)?galleryStyles.vr:""}` } ref={ref}>
            {(!loaded)?(<div className={`fullscreen fcenter`}>
            <div>
                <LoaderWheel status={status}></LoaderWheel>
            </div>
             </div>): null }
             

            <section id="galleryContainer" className={`${galleryStyles.galleryContainer}`}>
            
        
            {(showLanding && !Globals.instance().serverApp && swiperInstance && categories && (categories.length > 1)) ? 
                (<div className={`${galleryStyles.landingContainer}`} style={{background: (format && !showLanding) ?"transparent":backgroundColor}}>
                    
                    <div className={`${galleryStyles.letterbox}`}>
                        <div className={`${galleryStyles.heading}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'], props.theme))}>
                              {props.model.title}
                        </div>
                        <div className={`${galleryStyles.itemsContainer}`}>
                            {categories.map((category, i) => (
                            <div 
                                className={`${galleryStyles.itemContainer} ${galleryStyles.categoryThumb} ${(activeCategory === category.id)?(galleryStyles.choiceOn):("")}`}
                                key={'gallery_thumb_' + i}
                                onClick={() => handleCategoryThumbnailClick(category.id, category.short_name)}
                            >
                                <img src={getContentPath(category.thumbnail)} alt=""></img>
                                <div className={`${galleryStyles.caption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>{category.name}</div>
                              
                            </div>
                            ))}
                        </div>
                    </div>

                    {( backgroundImage && <img src={getContentPath(backgroundImage)} alt="" className={`${galleryStyles.backgroundImage}`}/>)}

                </div>)
                : null}

                {(Globals.instance().serverApp && showDefault) &&
                    (<div className={`${galleryStyles.landingContainer}`} style={{background: format ?"transparent":backgroundColor}}>
                        
                        {( props.model && props.model.default_image && <img src={getContentPath(props.model.default_image)} alt="" className={`${galleryStyles.backgroundImage}`}/>)}
                    </div>)}

                    {format && <img src={getContentPath(format.file)} alt="" className={`${galleryStyles.backgroundImage} force3d bkg`}></img>}   

                <React.Fragment>
                    <div id="galleryMain" className={galleryStyles.frame} style={format?formatStyle :( {background: backgroundColor})}>

                    
                        {(swiperInstance && !Globals.instance().serverApp)? (
                            <div id="galleryControlsContainer" className={`${galleryStyles.galleryControlsContainer} ${(((files.length === 1)&&(activeFileType==="image"))||(!showSliderNav))?galleryStyles.controlsOff:''}`}>
                                <div className={`${galleryStyles.galleryControls} ${(activeFileType === "video")?(galleryStyles.galleryControlsWide):""}`}>
                                    
                                    <div onClick={() => handleGalleryPrev(activeFile)} className={`${galleryStyles.galleryPrev}`}>
                                        <ChevronThinButton></ChevronThinButton>
                                    </div>
                               
                                {(activeFileType === "video")? <div onClick={() => handleFullScreen()} className={`${galleryStyles.galleryFullScreen}`}>
                                
                                    {!isPlaying?<PlayButton />:<PauseButton/>}
                                    
                                    </div> : null } 
                                    <div onClick={() => handleGalleryNext(activeFile)} className={`${galleryStyles.galleryNext}`}>
                                        <ChevronThinButton></ChevronThinButton>
                                    </div>
                                </div>
                            </div>) : null}
                            <span 
                            ref={refGalleryMain} 
                            style={formatSwiperStyle}
                            className={galleryStyles.swiperFrame}
                            >
                            <Swiper 
                                preloadImages={true} 
                                longSwipes={false} 
                                // allowTouchMove={false}
                                onSwiper={(swiper) => onSwiper(swiper)} 
                                onSlideChange={(swiper) => onSlideChange(swiper)} 
                                loop={false}
                                
                            >
                            {
                            files
                            .sort((a, b) => a.ordering - b.ordering)
                            .map((file, i) => (
                        
                                <SwiperSlide 
                                    key={'sw-slide-'+i}
                                    className={`${(file.categories.includes(activeCategory)||(activeCategory === 0))?(galleryStyles.swiperSlideOn):(galleryStyles.swiperSlideOff)} ${(activeFile === file.id)?("activeSlide"):""}`}
                                    
                                >
                                    <div 
                                        className={`${galleryStyles.swiperSlide} ${(file.fileType === 'vr')?('vrSlide'):("")} ${(file.fileType === 'compare')?(galleryStyles.compareSlide):("")}`}
                                        data-filetype={(file.fileType === 'vr')?(file.fileType):(file.fileType)} 
                                        data-file={file.file} 
                                        data-fileid={file.id}
                                        // data-target={(file.fileType === 'vr')?('file_vr_' + file.id):""}
                                        style={formatSwiperStyle}
                                        data-target={"vrContainer"}
                                        // style={format?formatSwiperStyle :{}}
                                    >
                                        {(file.fileType === 'image') ? <img src={getContentPath(file.file)} className={`${galleryStyles.galleryImage}`} alt="" /> : ""}
                                        {(file.fileType === 'video') ? (
                                            (file.loop === true)?
                                            (<video id={"file_" + file.id} width="100%" height="100%" className={`${galleryStyles.galleryVideo}`} loop disablePictureInPicture allowFullScreen controlsList="nodownload noplaybackrate">
                                                <source src={getContentPath(file.file)} type="video/mp4" />
                                            </video>):
                                            (<video id={"file_" + file.id} width="100%" height="100%" className={`${galleryStyles.galleryVideo}`} disablePictureInPicture allowFullScreen controlsList="nodownload noplaybackrate">
                                                <source src={getContentPath(file.file)} type="video/mp4" />
                                            </video>)
                                        ) : null}
                                     
                                        {(file.fileType === 'vr') ? (
                                        <div className={`${galleryStyles.galleryImage}`}>
                                        {(activeVR)?(null):( 
                                            <div>
                                                <div className={`${galleryStyles.galleryGo}`} onClick={(e) => handleStartVR(file.id)}><VRButton></VRButton>Start VR</div>
                                                <img src={getContentPath(file.thumbnail)} className={`${galleryStyles.galleryImageVR}`} alt="" />
                                            </div>
                                        )}
                                        </div>
                                        ) : null}
                                        {(file.fileType === 'external') ? (
                                            <iframe aria-label="matterport" id={"file_" + file.id} title={'gallery_iFrame_' + i} src={file.external_src} frameborder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" allowfullscreen allow="xr-spatial-tracking" className={`${galleryStyles.galleryiFrame}`}></iframe> 
                                            
                                        ) : null}
            
                                        {(file.fileType === 'compare') ? 
                                        
                                        <ReactCompareSlider
                                            className={`${galleryStyles.galleryImage}`}  
                                            position={25}
                                            portrait={file.vertical_compare}
                                            boundsPadding={handleComparePadding("compareFile_" + file.id)}
                                            handle={
                                                <div className={(file.vertical_compare)?(`${galleryStyles.galleryHandle} ${galleryStyles.galleryHandleVertical}`):(`${galleryStyles.galleryHandle}`) }>
                                                <div className={`${galleryStyles.galleryHandleButton}`}>
                                                <div className={`${galleryStyles.galleryControlsContainer}`}>
                                                <div className={`${galleryStyles.galleryControls}`}>
                                                    
                                                    <div className={`${galleryStyles.galleryPrev}`}>
                                                        <ChevronThinButton></ChevronThinButton>
                                                    </div>
                                                    <div className={`${galleryStyles.galleryNext}`}>
                                                        <ChevronThinButton></ChevronThinButton>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                </div>
                                                <span className={`${galleryStyles.galleryHandleLine}`}></span>
                                                </div>
                                            }
                                            itemOne={
                                                <ReactCompareSliderImage 
                                                src={getContentPath(file.file)}
                                                id={"compareFile_" + file.id}
                                                style={{
                                                    ...styleFitContainer({
                                                        objectFit: 'contain',
                                                        objectPosition: 'center'
                                                    })
                                                }}
                                                alt="" />
                                            }
                                            itemTwo={
                                                <ReactCompareSliderImage 
                                                src={getContentPath(file.file_compare)} 
                                                style={{
                                                    ...styleFitContainer({
                                                        objectFit: 'contain',
                                                        objectPosition: 'center',
                                                    })
                                                }}
                                                alt="Image two" />
                                            }
                                            />
                                        
                                        : ""}
                        
                                    </div>
                                </SwiperSlide>
                            )
                            ) 
                            }
                            
                            </Swiper>      
                            </span>
                        
                        </div>
                        


            
                    {(loadVR) ? ( <div id="vrPopup" className={`${galleryStyles.vrPopup} ${(activeVR)?(galleryStyles.vrPopupOn):(galleryStyles.vrPopupOff)}`}>
            
                        {(!Globals.instance().serverApp)? (<div className={`${mstyles.closeBtnContainer} ${galleryStyles.vrCloseBtnContainer}`} ><div id="vrCloseBtn" className={`round-btn`} onClick={(e) => onClickCloseVR()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}><CloseButton></CloseButton></div></div>):null}
                     
                        <ThreeVR 
                            file={vrFile && vrFile[0]} 
                            load={(loadVR)?true:false}
                            vrZoom={vrZoom}
                            vrRef={vrFile && vrFile[1]}
                            setVrZoom={setVrZoom}
                            rotation={vrFile && vrFile[2]}
                            startPos={vrFile && vrFile[3]}
                            endPos={vrFile && vrFile[4]}
                            socket={props.socket}
                            socketPrefs={props.socketPrefs}
                            // remoteMouse={remoteMouse}
                        >
                        </ThreeVR>
                        {console.log(activeFile)}
                      
                        
                    </div>) : null}


            
            
                </React.Fragment>
             
            </section>

            {/* Gallery Nav */}
            {((props.standard === true)&&(!Globals.instance().serverApp)) && (

                <div id="rightNav" className={`${styles.menuContainer} ${styles.leftNav} ${(menuOpen)?(galleryStyles.menuOn):("") } ${(navOpen)?(styles.moduleNavOpen):(styles.moduleNavClosed)} ${(categories.length === 0)?galleryStyles.headingOff:''} ${(showLanding)?styles.categoryLandingOn:""}`}>

     

                    <div className={`${styles.menuColRight}`}>

                    <span className={styles.menuInnerColumn}>
            

                        {props.logo && <img src={getContentPath(props.logo)} alt="logo" className={`${styles.logo} logo`} />}
                            <div className={`${styles.menuTitle}`}>{props.model.heading}</div>

                        {!showModules && <span className={styles.switchMenu} onClick={() => setShowModules(showModules => !showModules)}><span className={styles.switchMenuBtn}><Apps /></span><h4>{props.model.title}</h4></span>}

                        {showModules && props.icons &&
                        
                            <div className={`${styles.menuColPos} ${showModules?styles.menuColOn:""}`}><div className={`${styles.menuModuleContainer} ifServerHide`} style={Globals.instance().getThemeStyle(['nav-modules-container'],props.theme)}>
                            
                            {props.icons.map( (icon,index) =>{
                                return (
                                    <div className={`${styles.moduleLink}`} key={`nar-menu-mode-item-${index}`} index={index-1} 
                                    onClick={() => onIconClick(index-1)}>
                                        <span className={`${styles.moduleLinkIcon}`}> 
                                            {icon}
                                        </span>
                                    </div>
                            )})}
                            </div></div>
                    
                        }

                        {!showModules && 
                    
                            <span className={`galleryMenu ${styles.menuLink}`}>
                                
                                <div 
                                    className={`${galleryStyles.navHeading} ${menuOpen?galleryStyles.menuOpen:""}`}
                                    style={Globals.instance().getThemeStyle(['module-heading-bg'],props.model.theme)}
                                    onClick={() => (categories.length > 1)? handleMenu() : console.log('no categories')}
                                >
                                    <div
                                    className={`${galleryStyles.moduleHeading}`}
                                    style={Globals.instance().getThemeStyle(['module-heading'],props.model.theme)}>
                                    

                                        <span className={`${galleryStyles.moduleHeadingText}`}>{activeCategoryHeading}</span>

                                        {(categories.length > 1) ? <div className={`round-btn ${galleryStyles.navBurger}`}>
                                            <CaretDown />
                                        </div> : null}
                                        
                                    </div>

                                    {(menuOpen && <div id="gallerySubNav" className={`${galleryStyles.navSub}`}>{categories.map((category, i) => (
                                        <div 
                                            className={`${galleryStyles.dropdownChoice} ${(activeCategory === category.id)?(galleryStyles.choiceOn):("")}`}
                                            key={'gallery_category_' + i}
                                            // style={Globals.instance().getThemeStyle(['module-dropdown'],props.theme)}
                                            onClick={() => handleCategoryClick(category.id, category.short_name, true)}
                                        >
                                        {category.name}
                                    
                                        </div>
                                    ))}</div>)}


                                </div>
                                
                        

                                                    
                                
                                <div 
                                id="galleryThumbnails"
                                className={`${galleryStyles.galleryThumbnails} ${(menuOpen)?(galleryStyles.off):("")}`} >
                                {files
                                    .sort((a, b) => a.ordering - b.ordering)
                                    .map((file, i) => (
                                    <div 
                                        onClick={() => handleThumbnailClick(file.id, file.fileType, file.file, file.categories)}
                                        key={'gallery_file_' + i} 
                                        className={`${galleryStyles.galleryThumbnail} ${(thumbnailTransition === false)?(galleryStyles.ready):(galleryStyles.fade)} ${(activeFile)?((activeFile === file.id) ? (galleryStyles.galleryThumbnailActive): (galleryStyles.galleryThumbnailInactive) ): ""} ${(file.categories.includes(activeCategory)||(activeCategory === 0))? (galleryStyles.galleryThumbnailOn): (galleryStyles.galleryThumbnailOff)}`}
                                    >
                                    <img src={getContentPath(file.thumbnail)} className={`${galleryStyles.thumbnail}`} alt="" />
                                    {(file.caption) ? 
                                        <div 
                                        className={`${galleryStyles.caption}`}
                                        style={Object.assign({}, Globals.instance().getThemeStyle(['module-heading'],props.theme))}
                                        >{file.caption}</div> 
                                        : null}
                                    </div>
                                ))}
                                </div>
                                
                                </span>}

                    
                            
                    
                            </span>
                      
                    </div>
                
                
                                
                    <div className={`${styles.buttonContainer} ifServerHide`}>
                    {!showLanding && <span className={styles.buttonContainerBkg} ref={refBtnContainerBkg} />}
                    {!showLanding &&  <span className={styles.homeBtnCol}>
                            <div className={`${styles.buttonContainerMenu}`}>
                            <div onClick={() => onClickHide()} ><div className={`round-btn`}><Burger /></div>
                        </div>
                        </div>
                        {backPageIndex && <div className={`${styles.returnBtn}`}>
                        <div onClick={() => onClickReturn()} ><div className={`round-btn`}><Return /></div>
                            </div>
                        </div>}
                    </span>}
           
                       <span className={styles.homeBtnCol}>
                       {navOpen && <div className={`${styles.homeBtn}`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <Home />
                        </div>}
    

                        <div className={`${styles.toolBtn}`}><ToolsMenu theme={props.theme} 
                        setShowDrawControls={props.setShowDrawControls} 
                        setShowControls={props.setShowControls} 
                        setShowMarkup={props.setShowMarkup}
                        model={props.model}
                        
                        ></ToolsMenu></div>
                        </span>   

        
                                
                                

                        

                </div>

                <div className={`${styles.menuBurger} ${(navOpen && !showLanding)?styles.hide:""}`} onClick={() => onClickHide()} ><div className={`round-btn`}><Burger /></div></div>

        
                                
                </div>
                                
            )}
            
         
       
        </div>
        
    )

})

export default GalleryModule


// <div className={`${mstyles.closeBtnContainer} ${galleryStyles.closeBtnContainer}`} >
         
// <ToolsMenu theme={props.theme} 
// setShowMarkup={props.setShowMarkup}
// setShowDrawControls={props.setShowDrawControls} 
// model={props.model}  
// location={props.history.location}
// key={`tools-menu`}></ToolsMenu>

// <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
//     <CloseButton></CloseButton>
// </div>
// </div>
