import React,{useEffect,useState,useCallback,useRef} from 'react'
import { connect } from "react-redux"
import {getContentPath}  from './AssetManager'
import Globals from './Globals'
import {fetchNarrativeGroups,fetchNarratives,fetchThemes} from "./AssetManager"
import {setInitialized, setNarrativeTree, setNarratives, setThemes, setUser} from 'store/actions'
import history from './navigation/MainRouterHistory'
import axios from 'axios'
import LoaderWheel from './components/LoaderWheel'



const  Launch = (props)=>{
    
    const [status, setStatus] = useState('Starting');
    // const [themesLoaded, setThemesLoaded] = useState(false);
    const deviceSnRef = useRef();
    
    function loadFontList(){
        return new Promise( (resolve,reject)=>{
            
            
            // axios.get(getContentPath('core/fonts/fonts.css?d=1')).then((response)=>{
                let eleStyle =document.querySelector("#fontdefinitions")
                if(eleStyle)eleStyle.remove()
                var fileref=document.createElement('link')
                fileref.setAttribute('rel', 'stylesheet')
                fileref.setAttribute('type', 'text/css')
                // fileref.innerHTML=response.data
                fileref.setAttribute('href', getContentPath('core/fonts/fonts.css'))
                document.getElementsByTagName('head')[0].appendChild(fileref);

                

                resolve()
            // }).catch( error =>{
            //     console.error(error)
            //     reject(error)
            // })
        })
    }


// In browsers that don’t yet support this functionality,
// playPromise won’t be defined.

    const onThemesLoaded =  useCallback(async  ()=>{
        

        setStatus('Loading Themes')
        
            let res= null
            let resNarratives;
            let resGroups;


            // setStatus('Fonts')

            await loadFontList()

            // let strUser = localStorage.getItem('user')
    
            // if (strUser) {
                
            // }

            try{
                resNarratives = await fetchNarratives()
                console.log('%c resNarratives', 'font-weight:bold', resNarratives)
            }catch(err){
                Globals.instance().showAlert('Could not fetch narrative')
            }
            
            

            props.setNarratives(resNarratives)
        
            // setStatus('Narrative Tree')

            try{
                resGroups = await fetchNarrativeGroups()
            }catch(err){
                Globals.instance().showAlert('Could not fetch narrative groups')
            }
            props.setNarrativeTree(resGroups);
            
            props.setInitialized(true)
            // setStatus('Welcome')
            
            // if(props.user)
            // setStatus('Welcome Back '+props.user.username)
            setTimeout(()=>{
                // console.log("REDIRECT")
                if(props.location && props.location.state && props.location.state.referrer)
                {
                    let path = props.location.state.referrer.pathname
                    let search = props.location.state.referrer.search
                    console.log("referrer ... " + path)

                    if (Globals.instance().electron) {
                        Globals.instance().appPath = props.location.state.referrer.pathname.replace('/index.html','');
                    } else if (Globals.instance().ios) {
                        // NEED TO FIX THIS
                        // Globals.instance().appPath = ""
                    } else {
                        Globals.instance().appPath = ""
                    }

                    
                    console.log('appPath', Globals.instance().appPath,  props.location.state.referrer.pathname)
                    
                    history.replace({pathname:Globals.instance().getRoute(path),search:search})

                }
                else if(props.location && props.location.hash.indexOf('#/redirect')===0)
                {   
                    // console.log("redirect")
                    let path = props.location.hash.replace('#/redirect','')
                    history.replace(Globals.instance().getRoute(path))
                }
                else{
                    history.replace({ pathname: '/home' })
                }

            },Globals.instance.iosOverlay === true ? 0 : 1000)
       
    },[props])

    async function getUserRestrictions(username){
       
        return new Promise((resolve,reject)=>{
            axios.get(Globals.instance().adminAPI+'/api/core/user-restrictions/'+username).then(response=>{
                resolve([response.data.narratives, response.data.market, response.data.category, response.data.device_sn, response.data.link_token, response.data.org, response.data.default_aspectratio, response.data.letterbox, response.data.default_devicesize, response.data.display_ip])
                // console.log("getUserRestrict", [response.data.narratives, response.data.market, response.data.category, response.data.device_sn, response.data.link_token, response.data.org])
            }).catch(err=>{reject(err)})
        })
    }
    async function verifyToken(token){
        // let obj={}
        
        // if(cancelToken)obj.cancelToken=cancelToken.token
        return new Promise((resolve,reject)=>{
            
            if(Globals.instance().iosOverlay === true) {
                console.log("skip verify")
                //ignore verifying token on overlays
                resolve()
                return
            }
            // let path = Globals.instance().adminOriginAPI +'/api-token-auth/'
            
            axios({
                method: 'get',
                baseURL: Globals.instance().adminOriginAPI,
                url: 'auth/users/me/',
                headers: {  Authorization: 'Token ' + token }
                })
                .then(response => {  resolve(response.status===200?'success':'failure')  }).catch(function (error) {
                    console.error(error)    
                    if (error.response) {
                        // Request made and server responded
                        console.log("not verified ")
                        reject(error)
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("could not hear from server")
                        resolve('resolve not verified')
                      } else {
                          // Something happened in setting up the request that triggered an Error
                          reject(error)
                      }

                    reject(error)
                })
        })
    }

    // function setUpAnalytics(){
    //     // if(process.env.NODE_ENV==="production" && !document.getElementById('analytics-script')){
    //     let eleAna = document.createElement("script")
    //     eleAna.id=('analytics-script')
    //     eleAna.setAttribute('src','//www.googletagmanager.com/gtag/js?id='+Globals.instance().analyticsId)
    //     eleAna.async=true
    //     console.log('google add')
    //     document.body.appendChild(eleAna)
    //     window.dataLayer = window.dataLayer || [];
    //     window.gtag=function(){window.dataLayer.push(arguments);}
    //     window.gtag('js', new Date());
    //     // window.gtag('config', 'G-'+Globals.instance().analyticsId);
    //     window.gtag('config', 'G-'+Globals.instance().analyticsId, {send_page_view: false})
    //     // }/
    //     // console.log("SETUP ANALYTICS")
    // }

    useEffect(() => {
        if (props.user){
            let cancelSignal = axios.CancelToken.source();
            const getThemes = async () =>{
                try{ 
                    await props.setThemes(await fetchThemes(cancelSignal)) 
                
                }catch(err){
                    console.log(err)    
                    // Globals.instance().showAlert('Could not load themes')
                }
                onThemesLoaded()
            }
            getThemes();
        } 
    }, [props.user])

    
    useEffect(()  => { 
 
        let showDownload = localStorage.getItem('expo.downloadAlert')
        let downloaded = localStorage.getItem('expo.downloaded')
        // console.log('Globals.instance.downloadAlerts', Globals.instance().downloadAlerts)
        if ((!showDownload || showDownload.length === 0) && !Globals.instance().downloadAlerts) {
            let list = JSON.stringify([]);
            localStorage.setItem('expo.downloadAlert', list)
        }

        if (downloaded) {

            Globals.instance().downloaded = downloaded

          
        }
        
        let cancelSignal = axios.CancelToken.source();
        

        const onMount = async () =>{
            console.log('MOUNT')
            setStatus('Starting up')
            Globals.instance().nid=null
            
            let strUser = localStorage.getItem('user')
            // console.log('strUser', strUser)
            let narrativeId;
            let narrativeToken;
            if (props && props.location.state && props.location.state.referrer) {
                let params = new URLSearchParams(props.location.state.referrer.search)
                narrativeToken = params.get('u');
                let pathname = props.location.state.referrer.pathname
                let pathSplit = pathname.split('/narrative/');
                if (pathSplit.length > 0) {narrativeId = pathSplit[1]}
            } 
           
            if (narrativeToken && narrativeId) {
                setStatus('Verifying user from token')
             
                axios({
                    method: 'post',
                    baseURL: Globals.instance().adminOriginAPI,
                    url: 'api/core/verify-narrative-token/',
                    data: {
                        "link_token": narrativeToken,
                        "narrative_id": narrativeId
                    }
                    })
                    .then(response => {  
                        let userObj = {}
                        userObj.narratives = [parseInt(narrativeId)]
                        userObj.market= null
                        userObj.category= "anon"
                        userObj.device_sn = null
                        userObj.link_token = narrativeToken
                        userObj.token = narrativeToken
                        console.log('linkToken userObj', userObj)
                        props.setUser(userObj)
                    }).catch(function (error) {
                        console.error(error.response)    
                        
                    })
    
            } else {

                let userObj=JSON.parse(strUser)

                console.log("userObj at Launch", userObj)
              
                if(userObj){
                    setStatus('Verifying user')
                    try{
                        let resVerify = await verifyToken(userObj.token,cancelSignal)
                        // setStatus('checking restrictions')
                        if(resVerify!=='resolve not verified')

                        var userObjArray = await getUserRestrictions(userObj.username)
                        if (userObjArray) {
                            // console.log('userObjArray', userObjArray)
                            userObj.narratives= userObjArray[0]
                            userObj.market= userObjArray[1]
                            userObj.category= userObjArray[2]
                            userObj.device_sn = userObjArray[3]
                            userObj.link_token = userObjArray[4]
                            userObj.default_aspectratio = userObjArray[6]
                            userObj.letterbox = userObjArray[7]
                            userObj.default_devicesize = userObjArray[8]
                            userObj.display_ip = userObjArray[9]
                            if (userObj.default_devicesize && userObj.default_devicesize.length > 1) {
                                Globals.instance().device_width = userObj.default_devicesize[0]
                                Globals.instance().device_height = userObj.default_devicesize[1]
                            }
                                
                            // userObj.default_aspectratio && Globals.instance() && !Globals.instance().electron
                            if (userObj.default_aspectratio && userObj.letterbox) {
                                let root = document.getElementById('main');
                       
                                if (window.innerWidth > 1366) {
                                    root.dataset.aspect = userObj.default_aspectratio
                                    root.style.aspectRatio = userObj.default_aspectratio
                                    root.classList.add('letterBoxed')
                                    Globals.instance().aspect_ratio = parseFloat(userObj.default_aspectratio)
                                } else {
                                    console.log('navigator.maxTouchPoints', navigator.maxTouchPoints)
                                    if (navigator.maxTouchPoints > 0) {
                                        root.classList.add('touchDevice')
                                    }
                                    
                                }
                                
                                // console.log('Globals.instance().aspect_ratio', Globals.instance().aspect_ratio)
                            }

                            console.log('userObjArray', userObjArray)
                            if (userObjArray[5]) {
                                userObj.org_logo = userObjArray[5]['logo']
                                userObj.org_name = userObjArray[5]['name']
                            }
                            
                        }
                        console.log('signed in userObj', userObj)
                        props.setUser(userObj);
                       
                    }catch(err){
                        console.log('user not verified ',userObj)
                        if (!Globals.instance().controllerApp) {
                            history.replace({ pathname: '/login' })
                        }
                        
                    }
                
                } else {
                    console.log('user not verified')
                    if (!Globals.instance().controllerApp) {
                        history.replace({ pathname: '/login' })
                    }

                }
            }

            
        }
        onMount()

        
  
    
        return ()=>{
            console.log("LEFT LAUNCH")
            // cancelSignal.cancel('stopping launch')
            // await props.setThemes(await fetchThemes(cancelSignal)) 
               
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return( 
        <div className={`fullscreen fcenter loadingPage`} >
                <div className={`fullscreen fcenter`} >
                    <LoaderWheel status={status}></LoaderWheel>
                </div>
        </div>
    )
        
}


function mapDispatchToProps(dispatch) {    
    return {
        setInitialized: val => dispatch(setInitialized(val)),
        setNarrativeTree: tree => dispatch(setNarrativeTree(tree)),
        setNarratives: narratives => dispatch(setNarratives(narratives)),
        setThemes: themes => dispatch(setThemes(themes)),
        setUser: val => dispatch(setUser(val))
    };
  }
  

  const mapStateToProps = state => {
    const {themes,user} = state
    return {themes,user};
};
  
  export default connect(mapStateToProps, mapDispatchToProps)(Launch)


