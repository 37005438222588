import React,{forwardRef,useEffect,useRef,useState} from 'react';
import mstyles from '../modules.module.css'
import styles from './documents.module.scss'
import {getContentPath} from  'system/AssetManager'

import LoaderWheel from 'system/components/LoaderWheel'
import TestPDF from 'assets/test.pdf'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'
import { pdfjs, Document, Page } from 'react-pdf';
import {ReactComponent as ArrowLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as ArrowRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as Download} from 'assets/icons/download_file.svg'
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

// import { PDFDocumentProxy } from 'pdfjs-dist';

import Globals  from 'system/Globals'





// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.js',
//     import.meta.url,
// ).toString();

//   const options = {
//     cMapUrl: '/cmaps/'
//   };

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const DocumentsModule = forwardRef((props, ref)=>{

    const refPageNumber = useRef();
    const refServerAppListener =  useRef();
    const refServerPageAppListener = useRef();
    const refContainer = useRef()
    const [format,setFormat] = useState(null)
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState(0);
    const [pdfPath, setPdfPath] = useState();
    const [backgroundColor, setBackgroundColor] = useState('#6a6a6a');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    useEffect(()=>{
        let arr = []
        if(props.model.docs)
            arr=arr.concat(props.model.docs)
        if(props.model.screenshot && props.model.screenshot.document)
            arr.push(props.model.screenshot)
        setFiles(arr)
        if (props.socket) {
            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'select-module?module=' + props.index]);
            console.log('page handsake sent', 'select-module?module=' + props.index);
        }
        
            
    },[props.model.docs, props.model.screenshot])

    function onClickDoc(evt){
        
        let index = Number(evt.currentTarget.getAttribute("index"))
        let d = files[Number(index)]
        let f = d.document
        if(!f )return
        if(f.indexOf("http")===-1){
            f=Globals.instance().webURL+'/media/'+f;
        }
           
        if (window.webkit && window.webkit.messageHandlers) {

            // window.webkit.messageHandlers.notification.postMessage({ Object: 'SHARE', href:f , title:'Brookfield '+f.split('/').pop()})
            if (navigator.share) {
                navigator.share({
                  title: ''+f.split('/').pop(),
                  url: f,
                })
                  .then(() => console.log('Successful share'))
                  .catch((error) => console.log('Error sharing', error));
              }
        }else{
            let ele= document.createElement("a")
            ele.download=f.split('/').pop()
            ele.href=f
            ele.target="_blank"
            document.body.appendChild(ele)
            ele.click()
            // console.log(ele)
            document.body.removeChild(ele)
        }
    }

    useEffect(()=>{

        if (Globals.instance().electron || Globals.instance().ipad) {
           
            if (Globals.instance().ipad) {
                pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
            } else {
                pdfjs.GlobalWorkerOptions.workerSrc = Globals.instance().appPath + '/pdf.worker.js';
            }
        } else {
            pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
        }
        
        console.log('pdfjs', pdfjs.GlobalWorkerOptions.workerSrc)


        props.preAnimateWindowIn()
        // refContainer.current.style.display='block'
        setLoading(false)
        props.animateWindowIn()

        if (props.model.backgroundColor) {
            setBackgroundColor(props.model.backgroundColor);
        }

        if (props.model.formats) {
                  
            setFormat( Globals.instance().getFormat(props.model.formats))
            console.log('setting document format', Globals.instance().getFormat(props.model.formats), props.model.formats)
       
        }
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{ 
        if (files) {
    
            if (Globals.instance().serverApp || props.socketPrefs.mode === "isReceive") {
          
         
                window.removeEventListener('server-event', refServerAppListener.current)

                const serverAppListener = (evt)=>{
                    let detail = evt.detail
                    if(!detail)return
                    if(detail.event==='module-pdf-select-file'){ 
                       
                        showPdf(detail.command)

                        if (props.socket) {
                            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-pdf-select-file?command=' + detail.command]);
                            console.log('page handsake sent', 'module-pdf-select-file?command=' + detail.command);
                        }

                       
                
                    }
                
                }
               
                refServerAppListener.current = serverAppListener
                window.addEventListener('server-event', refServerAppListener.current)
                
            }
        } 
        
    },[files]);

    useEffect(()=>{ 
        if (pages && pages > 0 && pdfPath) {
    
            if (Globals.instance().serverApp || props.socketPrefs.mode === "isReceive") {
          
         
                window.removeEventListener('server-event', refServerPageAppListener.current)

                const serverAppListener = (evt)=>{
                    let detail = evt.detail
                    if(!detail)return
                
                    if(detail.event==='module-pdf-command'){ 
                        
                       switch(detail.command) {
                            case "prev":
                                onClickLeft()

                                if (props.socket) {
                                    props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-pdf-command?command=' + detail.command]);
                                }

                                
                
                                break;
                            case "next":
                                onClickRight()
                                if (props.socket) {
                                    props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-pdf-command?command=' + detail.command]);
                                }
                                break
                            
                            case "close":
                                closePdf()
                                if (props.socket) {
                                    props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-pdf-command?command=' + detail.command]);
                                }

                                break;
 
                            default:
                                {}
                       }
                    }
                }
               
                refServerPageAppListener.current = serverAppListener
                window.addEventListener('server-event', refServerPageAppListener.current)
                
            }
        } 
        
    },[pages, pdfPath, pageNumber]);


    const onError = (error) => {
        console.log('load error', error)
    }

    const onDocumentLoadSuccess = (pdf) => {
        // setPdf(pdf);
       
        setPages(pdf.numPages);
        setLoading(false);


        console.log('onDocumentLoadSuccess', pdf.numPages)
      };

    function onClickClose(evt){props.closeModule()}


    function onClickLeft(){
        let newPageNumber = pageNumber-1;
        console.log('onClickLeft', newPageNumber, pages, pageNumber)
        if ((newPageNumber) > 0) {
            setPageNumber(newPageNumber)

            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                props.socket.emit('cast', [props.socketPrefs.room, 'module-pdf-command?command=prev']);
            }
        }
        

    }
    function onClickRight(){
        
        let newPageNumber = pageNumber+1;
        console.log('onClickRight', newPageNumber, pages, pageNumber)
        if ((newPageNumber) <= pages) {
            setPageNumber(newPageNumber)
            
            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                props.socket.emit('cast', [props.socketPrefs.room, 'module-pdf-command?command=next']);
            }
        }
        
    }

    function showPdf(path){
        setLoading(true)
        setPdfPath(path)

        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                
            props.socket.emit('cast', [props.socketPrefs.room, 'module-pdf-select-file?command=' + path]);

        }
    }

    function closePdf() {
        console.log('close')
        setPdfPath(null)
        setPages(null)
        setPageNumber(1);

        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
            props.socket.emit('cast', [props.socketPrefs.room, 'module-pdf-command?command=close']);
        }
    }

    const loaderEle = () => (<div className={`fullscreen fcenter ${styles.loader}`}><div><LoaderWheel></LoaderWheel></div></div>);

    const loaderElePage = () => (<div className={`${styles.pageLoader}`}><div><LoaderWheel></LoaderWheel></div></div>);

    const formatStyle = format && user && user.letterbox ? {
        'background': 'transparent',
        'width': format.inset_width * 100 + "vw",
        'maxHeight': format.inset_height * 100 + 'vh',
        'left': format.inset_x * 100 + "vw",
        'top': format.inset_y * 100 + "vh",
        'aspectRatio': (format.inset_aspect_ratio ? format.inset_aspect_ratio.toString() : format.aspect_ratio),
        'position': 'absolute',
    } : {}


    return (
        <div style={{background: pdfPath ?"transparent":backgroundColor}} className={`fullscreen  ${styles.container}` } ref={ref}>


        {pdfPath && format && <img src={getContentPath(format.file)} alt="" className={`${styles.backgroundImage} force3d bkg`}></img>}   
        {(props.model.background_image && <img src={getContentPath(props.model.background_image)} alt="" className={`${styles.backgroundImage} bkg`}/>)}
        

             
        {(loading)?(loaderEle): null }
        
     

        {pdfPath && <div className={`${styles.pdfBox}`}  style={format?formatStyle :( {background: backgroundColor})}>
        <Document 
            file={getContentPath(pdfPath)} 
            // options={options}
            // file={'./public/test.pdf'}
            loading={loaderEle}
            onLoadSuccess={onDocumentLoadSuccess} 
            onLoadError={onError}
            onSourceError={onError}
            className={`${styles.pdf}`}
           
        >
            <Page 
                pageNumber={pageNumber} 
                // width={500}
                loading={""}
                scale={1}
                className={`${styles.pdfPage}`}
                canvasBackground={backgroundColor}
                />

                <div className={`${styles.pageNumber}`}>
                {pageNumber} of {pages}
            </div>
        </Document>

     
  

       
  
        </div>}

        {!pdfPath && <div className={`fullscreen  ${styles.scrollContainer} hide-scroller` } >
        
        <div className={`${styles.landingContainer}`} style={{background: (props.model.background_image) ?"transparent":backgroundColor}}>
        
        

        <div className={`${styles.letterbox}`} >
            <div className={`${styles.heading}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))} >
                    {props.model.title}
            </div>
            

            <div className={`fullscreen ${styles.itemsContainer}`} ref={refContainer}>
         
                { files &&
                    files.map((d,index)=>{
                        return(<div key={`docs-menu-${index}`} index={index}  className={`${styles.thumbnail}`} >
                            <img src={getContentPath(d.image)} alt="" onClick={() => showPdf(d.document)} />
                            <div className={`${styles.caption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}><span onClick={() => showPdf(d.document)}>{d.caption}</span><span className={`${styles.downloadBtn}`} onClick={onClickDoc}><Download /></span></div>
                                 
                        </div>)
                    })
                    
                
                }
   
            </div>

            

        </div>
      
        </div>
        </div> }

        {pdfPath &&  <div className={`${styles.controlsPill}`}>
                <ArrowLeft onClick={onClickLeft}></ArrowLeft>
                <ArrowRight  onClick={onClickRight}></ArrowRight>
            </div>      
        }


        <div className={`${mstyles.closeBtnContainer} ${styles.closeBtnContainer}`}>
                <div className={`round-btn  `} onClick={() => {pdfPath ? closePdf() : onClickClose()}}>
                    <CloseButton></CloseButton>
                </div>
            </div>
        </div>

        
    )

})

export default DocumentsModule

// className={`${styles.thumbnail}`} onClick={onClickDoc}

// 
