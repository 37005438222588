import React,{forwardRef,useState,useEffect} from 'react'
import { connect } from "react-redux";
import styles from '../styles/login.module.scss'
import gsap,{Power2} from 'gsap'
import LoaderWheel from './LoaderWheel'
import axios from 'axios'
import {setUser} from 'store/actions'
import Globals from 'system/Globals'

const LoginForm = forwardRef((props,ref)=>{

    const [username,setUsername] =useState("")
    const [password,setPassword] =useState("")
    const [email,setEmail] =useState("")
    const [errorUsername,setErrorUsername]=useState("")
    const [errorPassword , setErrorPassword]=useState("")
    const [errorEmail , setErrorEmail]=useState("")
    const [loading , setLoading ] =useState(false)
    const [loggedIn , setLoggedIn ] =useState(false)
    const [mode,setMode]=useState("login")

    function onChangeUsername(evt){
        const strippedString = evt.target.value.replace(/(<([^>]+)>)/gi, "")
        if(strippedString.length>0)
            setErrorUsername("")
        setUsername(strippedString)
    }

    function onChangePassword(evt){
        const strippedString = evt.target.value.replace(/(<([^>]+)>)/gi, "")
        if(strippedString.length>0)
            setErrorPassword("")
        setPassword(strippedString)
    }

    function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }


    function onChangeEmail(evt){
        const strippedString = evt.target.value.replace(/(<([^>]+)>)/gi, "")
        if(strippedString.length>0)
            setErrorEmail("")
        if(!validateEmail(strippedString))
            setErrorEmail('not valid email format')
        setEmail(strippedString)
    }

    function onClickGuest(){
        props.setUser({token:null,username:'guest',type:'guest'})
        setLoggedIn(true)
    }

    function onKeyUp(event) {
        if (event.keyCode === 13) {// Number 13 is the "Enter" key on the keyboard
          event.preventDefault();  // Cancel the default action, if needed
          onClickEnter()
        }
    };
    function onClickForgotPassword(event){setMode("forgot")}
    function onClickBackToLogin(){setMode("login")}
    function onClickContinueForgot(){}
    useEffect(()=>{
        if(loggedIn){
            gsap.to(ref.current,{duration:0.5,opacity:0,ease:Power2.easeInOut ,onComplete:()=>{
                props.onLogin()    
            }})
        }
    },[loggedIn, props, ref])

    function onClickEnter(){
        //validate
        let err=false
        if(username.length === 0){
            setErrorUsername("Please Enter an Username")
            err=true
        }
        if(password.length === 0){
            setErrorPassword("Please Enter a Password")
            err=true
        }
        if(err)return
        
        setLoading(1)
        if(Globals.instance().mode==="static-web"){
            props.setUser({token:'token',username:'test',type:'user',narratives:['citypoint']})
            setLoggedIn(true)
            return
        }
        let path = Globals.instance().adminOriginAPI +'/api-token-auth/'
        
        axios.post(path,{
            username:username,
            password:password
        }).then((response)=>{
            setLoading(false)
            axios.get(Globals.instance().adminAPI+'/api/core/user-restrictions/'+username).then(response2=>{
                console.log(response2.data)
                props.setUser({token:response.data.token,username:username,type:'user',narratives:response2.data.narratives?response2.data.narratives:[],'market':response2.data.market, 'category':response2.data.category, 'device_sn': response2.data.device_sn, 'org': response2.data.org})

                if (Globals.instance().ios) {
                    window.webkit.messageHandlers.notification.postMessage({ Object: "token", Data: response.data.token });
                }

                if(Globals.instance().electron){
                    if(window.api && window.api.send) {
                        window.api.send("toApp",  {"event":"sync-app"});
                        return
                    }
                }

            })
            setTimeout(() => {
                setLoggedIn(true)
            }, 300);
        }).catch(error=>{
            setLoading(false)
            setErrorPassword("no user found for password")
            console.error(error)
        })

    }

    return (
        <div className={`${styles.loginContainer}`} ref={ref} loading={loading?1:undefined}>
            <form>
            <input type="text" name="email"  autoComplete={"username email"} style={{display:"none"}}></input>
            {mode==="login" && (
                <React.Fragment>
                    <div className={`${styles.loginRow}`}>
                        <input name="username" value={username}  autoComplete="off" placeholder="Username" type="username" autoCapitalize="none" autoCorrect="off" onChange={onChangeUsername} onKeyUp={onKeyUp}></input>
                        <label className={`${styles.error}`} >{errorUsername}</label>
                    </div>
                    <div className={`${styles.loginRow}`}>
                        <input name="password" value={password} placeholder="Password" type="password" autoComplete={'current-password'} onChange={onChangePassword}  onKeyUp={onKeyUp}></input>
                        <label className={`${styles.error}`} >{errorPassword}</label>
                    </div>
                    <div className={`${styles.loginRow}`}>
                        <div className={`${styles.buttonEnter}`} onClick={onClickEnter}>Enter</div>
                        {/* <div className={`${styles.buttonContinue}`} onClick={onClickGuest}>Continue Guest</div> */}
                    </div>
                    {/* <div className={`${styles.loginRow}`}><div className={`${styles.buttonForget}`} onClick={onClickForgotPassword}>Forgot Password</div></div> */}
                    
                    {loading &&(
                        <div className={`${styles.wheelContainer}`}><LoaderWheel ></LoaderWheel></div>
                    )}
                </React.Fragment>
            )}
            { mode==="forgot" && (
                 <React.Fragment>
                    <div className={`${styles.loginRow}`}>
                    <input name="email" value={email}  placeholder="email" type="email" onChange={onChangeEmail} onKeyUp={onKeyUp}></input>
                    <label className={`${styles.error}`} >{errorEmail}</label>
                    </div> 
                    <div className={`${styles.loginRow}`}><div className={`${styles.buttonEnter}`} onClick={onClickContinueForgot}>Continue</div></div>
                    <div className={`${styles.loginRow}`}><div className={`${styles.buttonContinue}`} onClick={onClickBackToLogin}>Back to Login</div></div>
                </React.Fragment>       
            )
            }
            </form>
        </div>
    )

})



function mapDispatchToProps(dispatch) {
    return {setUser: val => dispatch(setUser(val))};
}

export default connect(undefined, mapDispatchToProps,undefined,{forwardRef:true})(LoginForm)

