import style from './styles/remotecoms.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Globals from 'system/Globals';
import { useHistory } from "react-router-dom";
import gsap,{Power3,Power2,Power1} from 'gsap'

import {ReactComponent as Cast} from 'assets/icons/cast.svg'
import {ReactComponent as Group} from 'assets/icons/group.svg'
import {ReactComponent as CallEnd} from 'assets/icons/call_end.svg'

function RemoteComs(props) {
  const history = useHistory();

  const roomId = useRef();
  const socketEle = useRef();

  const [isConnected, setIsConnected] = useState(props.socket && props.socket.connected);
  const [lastMessage, setLastMessage] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [showPeople, setShowPeople] = useState(false);
  const [resolved, setResolved] = useState(0);
  const [error, setError] = useState();
  // const [showWelcome, setShowWelcome] = useState(false);
  const [room, setRoom] = useState();
  const [entered, setEntered] = useState();
  const [clock, setClock] = useState();
  const [lastPrivate, setLastPrivate] = useState();

  
  // const isDev = useState(process.env.NODE_ENV === 'development')
  // const [loaded, setLoaded] = useState(false);


  function getMeeting(hash) {
    console.log('getMeeting', hash)
    axios.get(Globals.instance().adminAPI+'/api/meeting/'+ hash).then((results)=>{
      console.log(results.data)
      if (results.data) {
        roomId.current = results.data['room_id']
        let meetingclients = results.data['meetingclients']
        
        if (meetingclients) {
          setClients(meetingclients)
          setClientStatus(meetingclients);
        }  
      }
     
    }).catch(function (error) {
      props.setShowWelcome(false);
      history.replace({pathname:'/login/'})
      console.log(error)

    })
  }



  useEffect(() => {

    if (props.socket) {

      setTimeout(() => {
        socketEle.current.style.opacity = 1;
      }, 500);
      

   

      setLastMessage(null);

      if (props.socketPrefs && props.socketPrefs.mode === 'isBroadcast') {
        getMeeting(props.socketPrefs.meeting);
      }



    

    props.socket.on('clock', (data) => {
     setClock(data);
    })

    props.socket.on('connect', () => {
      setIsConnected(true);



    });
    props.socket.on('disconnect', () => {
      setIsConnected(false);
    });
    props.socket.on('test', data => {
      setLastMessage(data);

      if (data === "test") {
        let event = "narrative-module";
        let command = 0;
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
            console.log('event', data)
        }, 5000);
        
      }
    });

    console.log('socketMode', props.socketPrefs.mode)

    function processSocketData(data) {

      console.log('processSocketData', data)
     
        setLastMessage(data);
        

        function handleMenu(open){
          let event;
          let command = open;
          if (open !== "full") {
            event = "narrative-menu-open";
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
            console.log('dispatched narrative-menu-open')
          } else {
            event = "narrative-menu-open-full";
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
            console.log('handleMenu dispatched full')
          }
          
         
        }

        function closeModule(){
          let event = 'narrative-close-module'
          let command = ""
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("narrative-menu-open") !== -1) {
          let objs = data.split('?open=');
          handleMenu(objs[1])
        }



        if (data.indexOf('nav') !== -1) {
          let objs = data.split('nav#path=');

          props.history.push(objs[1])

          const url = objs[1].split('?')
          
          let params = new URLSearchParams(url[1])
          let open = params.get('nav')
          console.log('nav handling', params, objs[1], open)
        // }
          
         console.log('push to',  objs[1])

        }

        if (data.indexOf("narrative-menu-link") !== -1) {
          let event = "narrative-menu-link";
          // closeModule();
          let objs = data.split('?');
          let params = new URLSearchParams(objs[1])
          let link = params.get('page')
          let command = link;

          let origin = params.get('origin');
          if (origin && (window.location.href.indexOf(origin) === -1)) {
            console.log('origin to push', origin)
            props.history.push(origin)
            setTimeout(() => {
              window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
            }, 250);
            
          } else {
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
          }
          
          
          console.log('narrative-menu-link', command, origin, objs)
          handleMenu(false);
         
          
        }

        if (data.indexOf("select-module") !== -1) {
              let event = "narrative-module";
              let objs = data.split('?module=');
              let command = objs[1];
              console.log('event', event, command)
              window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
          }

          if (data.indexOf("narrative-module-id") !== -1) {
            let event = "narrative-module-id";
            let objs = data.split('narrative-module-id');
            let command = objs[1];
            console.log('event', event, command)
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

          if (data.indexOf("narrative-close-module") !== -1) {
            let event = "narrative-close-module";
            console.log('event', event)
            let command = "";
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("narrative-module-cta") !== -1) {
          let event = "narrative-module-cta";
          let objs = data.split('narrative-module-cta');
          let command = objs[1];
          console.log('event', event, command)
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("narrative-page-state") !== -1) {
          let obj = data.split('?command=');
          let event = "narrative-page-state";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        // Gallery

       

        if (data.indexOf("module-gallery-select-category") !== -1) {
          let obj = data.split('?command=');
          let event = "module-gallery-select-category";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("module-gallery-select-file") !== -1) {
          let obj = data.split('?command=');
          let event = "module-gallery-select-file";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("module-gallery-select-vrfile") !== -1) {
          let obj = data.split('?command=');
          let event = "module-gallery-select-vrfile";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        // 3D

          if (data.indexOf("module-webgl-select-view") !== -1) {
            let obj = data.split('?v=');
            let event = "module-webgl-select-view";
            let command = obj[1]
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
          }

          if (data.indexOf("joystick-mousemove") !== -1) {
            let pos = data.split('?pos=');
            console.log('joystick-mouse pos', pos)
            if (pos) {
              let objs = pos[1].split(',');
              console.log('x,y',objs[0], objs[1])
              let x = window.innerWidth * Number(objs[0])
              let y = window.innerHeight * Number(objs[1])
              // let cursor = document.getElementById("remoteCursor");
              // gsap.to(cursor, {x: x, y: y, duration: 0});
    
              let event = "webgl-mousemove";
              let command = x + "," + y;
    
              window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
              
            }
          }

          if (data.indexOf("module-gallery-command") !== -1) {
            let obj = data.split('?command=');
            let event = "module-gallery-command";
            let command = obj[1]
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
          }
          if (data.indexOf("module-webgl-command") !== -1) {
            let obj = data.split('?command=');
            let event = "module-webgl-command";
            let command = obj[1]
            window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
          }


        // PDF
        if (data.indexOf("module-pdf-select-file") !== -1) {
          let obj = data.split('?command=');
          let event = "module-pdf-select-file";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

        if (data.indexOf("module-pdf-command") !== -1) {
          let obj = data.split('?command=');
          let event = "module-pdf-command";
          let command = obj[1]
          window.dispatchEvent(new CustomEvent('server-event',{detail:{event:''+ event +'',command:''+command+''}}));
        }

          if (data.indexOf("leave") !== -1) {
            leaveRoom()
          }
          
  
    }
    

    if (props.socketPrefs && props.socketPrefs.mode === 'isReceive') {
      // document.getElementById('root').classList.add('receiveMode')
      props.socket.on(('cast'), data => {
        console.log('cast recd', data)
        processSocketData(data);

     }); 
     
     props.socket.on(('private'), data => {

      
      if (data) {
        console.log('private recd', data)
        processSocketData(data);
      }
      

      }); 
      
    }
  }

    return () => {
      props.socket.off('connect');
      props.socket.off('disconnect');
      props.socket.off('message');
    };
  }, [props.socketPrefs])

  useEffect(() => {
    if (clients) {
      if (props.socketPrefs && props.socketPrefs.mode === 'isBroadcast') {
        props.socket.on('cast', data => {

          setLastMessage(data);
      
          let currClients = [ ...clients ];
          let presenter = currClients.find(o => o.presenter === true)
          if (presenter) {
            presenter.message = data
          }
          
          setClientStatus(currClients)
          console.log('presenter update', currClients)
        });
  
        console.log('clients', clients)
  
        props.socket.on('client', data => {
  
          console.log('client data', data)
  
          let dataSet = data.split(',')
    
          const clientByEmail = clients.find(function(object) {
            return object.email === dataSet[1];
          });
          
          console.log('client recd', data, clientByEmail, clients)
  
            let newClients = [ ...clients ];
            newClients.push({
              'client_id': dataSet[0],
              'joined': true,
              'email': dataSet[1],
              'message': null,
              'forward': null
            });
    
            setClients(newClients);
            setClientStatus(newClients);
            
   
       
    
        });
  
      props.socket.on('handshake', data => {
        let objs = data.split(',');
        let currClients = [ ...clients ];
        let client = currClients.find(obj => obj.client_id === objs[0])

        console.log('handshake', client, lastMessage, objs, currClients)

        if (client && (client.message !== objs[1])) {

        
          client.message = objs[1]
          client.forward = null
          setClientStatus(currClients);
          console.log('handshake recd', currClients, objs)

        }
        
      });

      props.socket.on('join-handshake', data => {
        let objs = data.split(',');
        let currClients = [ ...clients ];
        let client = currClients.find(obj => obj.client_id === objs[0])

        console.log('join-handshake', client, lastMessage, objs, currClients)

        if (client && (client.message !== objs[1])) {

        
          
          if (lastMessage && (objs[1] !== lastMessage) && (objs[0] !== lastPrivate)) {
            
            props.socket.emit('private', [objs[0], lastMessage])
            console.log('private emitted', [objs[0], lastMessage])
            client.message = null
            client.forward = lastMessage
            setLastPrivate(lastMessage)
            setClientStatus(currClients);
         
          } else {
            client.message = objs[1]
            client.forward = null
            setClientStatus(currClients);
            setLastPrivate(null);
            console.log('join-handshake recd', currClients, objs)

          }
        }
        
      });

      props.socket.on('client-left', data => {

        let currClients = [ ...clients ];
        let index = currClients.findIndex(obj => obj.client_id === data)

        console.log('client-left', data)

        if (index > -1) {

          currClients.splice(index, 1);
    
          console.log('splice', index, currClients, clients)
          setClients(currClients)
          setClientStatus(currClients);
        

        }
        
      });
      
  
        
      }
    }
  }, [clients])

  useEffect(() => {
    console.log('clientStatus', clientStatus)
  }, [clientStatus])
  
  useEffect(() => {
        console.log('props.showWelcome', props.showWelcome, props.socket)
    if (props.showWelcome && props.socket) {
      let params = new URLSearchParams(new URL(window.location.href).search);
      const meeting = params.get('join');
      if (meeting) {
        
        getMeeting(meeting);
        
      }
    }
  }, [props.showWelcome, props.socket])
  

  useEffect(() => {
    if (clientStatus) {
      if (lastMessage) {
        console.log('lastMessage', lastMessage)
        let clientsResolved = clientStatus.filter(obj => ((obj.message === lastMessage) && obj.joined))
        setResolved(clientsResolved.length)
        console.log('clientsResolved', clientsResolved)
        console.log('resolved', resolved, clientsResolved.length)
      } 
      else {
        setResolved(clientStatus.filter(obj => obj.joined).length)
      }
      console.log('resolved', resolved, clientStatus, lastMessage)
    }
    
      
    
  }, [lastMessage, clientStatus, resolved])



  function copyInvite(e){

    console.log('props', props)
    if (props.socketPrefs.nid) {
      navigator.clipboard.writeText((process.env.NODE_ENV === 'development' ? "http://localhost:3000" : Globals.instance().webURL) + "/narrative/" + props.socketPrefs.nid + "?u=" + props.user.link_token + "&nav=open&join=" + props.socketPrefs.meeting)
    } else {
      navigator.clipboard.writeText((process.env.NODE_ENV === 'development' ? "http://localhost:3000" : Globals.instance().webURL) + "/home?u=" + props.user.link_token + "&join=" + props.socketPrefs.meeting)
    }
    
    // gsap.fromTo(e.target, {y: -4}, {y: 0, duration: 0.2});
    gsap.fromTo(e.target, {scale: 1.05}, {scale: 1, duration: 0.2})
    // gsap.to(e.target, {y: 10}, {y: 1, duration: 0.2});
  }
  function copyRoom(e){
    navigator.clipboard.writeText(props.socketPrefs.room)
    // gsap.fromTo(e.target, {y: -4}, {y: 0, duration: 0.2});
    gsap.fromTo(e.target, {scale: 1.05}, {scale: 1, duration: 0.2})
    // gsap.to(e.target, {y: 10}, {y: 1, duration: 0.2});
  }

  function leaveRoom(){
    setLastMessage(null);

    props.history.push({pathname:props.history.location.pathname,search:""})
    
    if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && clientStatus.length !== 1) {
      props.socket.emit('cast', [props.socketPrefs.room, 'leave']);
			// console.log('narrative-menu-link?page=', page + '&origin=/narrative/' + props.nid)
      
		}

    if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
      let url = Globals.instance().adminAPI+'/api/end-meeting/';
      let postData = {
        meeting_hash: props.socketPrefs.meeting
    }
      axios({
          method: "post",
          url: url,
          data: postData,
          headers: {  Authorization: 'Token ' + props.user.token}
      }).then((response) => {
          
          props.setSocketPrefs({'mode': null, 'room': null, 'nid': null, 'meeting': null})
         
          let root = document.getElementById('root');
          root.classList.remove('receiver');

      });
    }

    if (props.socketPrefs && props.socketPrefs.mode === "isReceive") {
      document.getElementById('meetingWelcome').remove();
      props.socket.emit('client-left', [props.socketPrefs.room, props.socket.id]);
    }

   
    props.socket.emit("leave", props.socketPrefs.room);
   
    // location.reload();

    let currClients = [...clientStatus]
    let index = currClients.findIndex(obj => obj.client_id === props.socketPrefs.client_id)
    currClients.splice(index, 1);
    
    console.log('splice', index, currClients, clients)
    setClients(currClients)
    setClientStatus(currClients);
    setShowPeople(false);

    setTimeout(() => {
      props.setSocketPrefs({'mode': null, 'room': null, 'clientid': null})
    }, 300);
   
  }


  function onSocketMsg(room, msg) {
    props.socket.emit('cast', [room, msg]);
  }
  
  function onSocketListeners(room) {
    props.socket.emit('listeners', room);
    console.log('listeners emit', room)
  }
  function startMeeting(){
    console.log('startMeeting', roomId.current)
    let room = roomId.current;
    if (room) {
      props.setShowWelcome(true);

      function onChangePrefReceive(room, email) {
        console.log('onChangePrefReceive', true, room, email)    
        sessionStorage.setItem('setpref-receive', 'true')
        props.setSocketPrefs({'mode': 'isReceive', 'room': room})
        props.socket.emit("join", [room, email])
        sessionStorage.setItem('setpref-socket', JSON.stringify({'mode': 'isReceive', 'room': room, 'email': email}))
        setEntered(true)
        // console.log('lastMessage on join', lastMessage)
    
        // setTimeout(() => {
        //   
        // }, 500);
        

        function updateMeeting(){
          // props.setShowWelcome(false)
          let root = document.getElementById('root');
          root.classList.add('receiver');
        }
        gsap.to('#meetingWelcome', {opacity: 0, duration: 0.3, onComplete:()=>updateMeeting()})
        
        
      }
      

      let email = document.getElementById('welcomeInput').value;
      if (email) {
        onChangePrefReceive(room, email);
      } else {
        setError("Please enter your name or email address")
      }

    

              
             
          // params.delete("join")
    }
  }

  function onEnterMeeting(e) {
    console.log('onEnterMeeting', e, e.key, e.keyCode)
    if (e.key === "Enter") {
      e.preventDefault();
      startMeeting();
    }
  }

  function sendPrivate(clientId) {
    props.socket.emit('private', [clientId, lastMessage])
  }

  // useEffect(() => {
  //   if (props.socketPrefs)
  // }, [props.socketPrefs])

  // useEffect(() => {
  //   if (entered && props.socket && props.socketPrefs && props.socketPrefs.mode === "isReceive") {
  //       // props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=])

  //       // props.history.push('/narrative/64?u=6560bff236812ad013cc04ada9b5c18ae281b677&nav=open')
  //       if (lastMessage) {
         

  //         props.socket.emit('private', [props.socketPrefs.client_id, lastMessage])

  //         console.log('lastMessage on join', [props.socketPrefs.client_id, lastMessage])
  //       }
  //       // console.log('no lastMessage on join', [props.socketPrefs.client_id, lastMessage])
  //   }
  // }, [props.socket, props.socketPrefs, lastMessage, entered])

  return (
    <div className={style.sockets} ref={socketEle}>

                
    

    <div className={`${style.panel} ${(showPeople)&&style.on}`} id="people">
      <h2>People</h2>
      <span className={`${style.ui} ${style.copyBtn}`} onClick={(e) => copyInvite(e)}>Copy Invite</span>

     
     <span className={`${style.clients}`}>{clientStatus && clientStatus.map((obj, i) => (
        (obj.joined && <span key={'client_' + i}  className={`${style.client}`}>
          <span className={style.clientRow} onClick={() => sendPrivate(obj.client_id)}>
            {obj.email}  
            {obj.presenter ? <span className={`${style.icon}`}><Cast /></span>:<span></span>}
            <span className={`${style.statusLight} ${((obj.message === lastMessage)||(!lastMessage)||(lastMessage && lastMessage.indexOf('vrfile-mousemove') === 0))? style.on : ""}`}></span>
          </span>

         
          </span>)
       ))}
      </span>
    

    </div>

     
    {props.socketPrefs.mode && <span className={`${style.icon} ${style.ui} ${style.callEnd}`} onClick={() => leaveRoom()}><CallEnd /></span> }

      {props.socketPrefs.mode && clientStatus && props.socketPrefs.mode === 'isBroadcast' && <div className={style.meetingIcons}>
      
      <span className={`${style.icon} ${style.ui} ${(showPeople)&&style.on}`} onClick={() => setShowPeople(showPeople => !showPeople)}>
        
          <Group /> {props.socketPrefs.mode && props.socketPrefs.mode === "isBroadcast" && <span className={`${style.clientCountBtn}`}><span className={`${style.clientCount} ${resolved === (clientStatus.filter(obj => obj.joined).length) ? "" : style.red}`}>{clientStatus.filter(obj => obj.joined).length}</span></span>}
        </span>
       
      </div>}

      {props.socketPrefs.mode && clientStatus && props.socketPrefs.mode === 'isReceive' && <div className={style.meetingIcons} onClick={() => setShowPeople(showPeople => !showPeople)}>
      <span className={`${style.icon} ${style.ui} ${style.receiveIcon}`}>
    
        <Cast /> 
       
      </span>
    </div>}
      
    {props.showWelcome && <span className={style.meetingWelcome} id="meetingWelcome" onKeyDown={(e) => onEnterMeeting(e)}>
    <span className={style.center}>
        <h1>Welcome to Expo</h1>
        <p>Please Join the Meeting to Experience A Live Demonstration of Expo's Collaborative Presentation System Where The Presenter Will Take You on a Guided Tour.</p>
        <input className={`${style.welcomeInput} ${error&&style.error}`} required id="welcomeInput" type="text" name="email" placeholder='Enter your email address' />
        {error && <span className={style.error}>{error}</span>}
        <span onClick={() => startMeeting()}><span className={style.button}>Enter</span></span>
    </span>
  </span>}

      </div>
  );
}

export default RemoteComs;

// <p><span className={`${style.ui}`} onClick={(e) => copyRoom(e)}> {props.socketPrefs.room}</span></p>


// <span className={style.lastMessage}>
// {clock}
// </span>

// <span className={style.lastMessage}>
// {lastMessage}
// </span>