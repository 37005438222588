
import React,{useEffect,useRef,useState,forwardRef} from 'react'
import styles from './controller-narrative.module.css'
import ControllerServerComs from 'system/ControllerServerComs'
// import nstyles from '../narrative/narrative.module.scss'
// import mstyles from '../narrative/modules/modules.module.css'
// import {ReactComponent as CloseButton} from '../assets/icons/close.svg'
// import Globals from 'system/Globals'

const ControllerPDFUI = forwardRef( (props ,ref)=>{
    

    useEffect(()=>{
     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[])

    // useEffect(() => {
    //     console.log("props.vidIsPlaying", props.vidIsPlaying)
    //     if (props.vidIsPlaying) {
    //         ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidplay");
    //     } else {
    //         ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidpause");
    //     }
    // }, [props.vidIsPlaying])

    return (
             
        <section ref={ref} className={`${styles.galleryPopup} ${styles.videoControls}`}>
        <div className={`${styles.videoButtons}`}>
            <div className={`${styles.videoBtnRow}`}>
                <div className={`${styles.videoBtn}`} onClick={props.onPrevClick}>Previous</div>
                <div className={`${styles.videoBtn}`} onClick={props.onNextClick}>Next</div>
            </div>

        </div>
        
    </section>
    )

})

export default ControllerPDFUI
