import React,{useState,useEffect,useRef} from 'react'
import { connect } from 'react-redux';
import {
    Router,
    Switch,
    Route,
    Redirect,
    HashRouter
} from "react-router-dom";

import Landing from '../Landing'
import Launch from '../Launch'
import Home from '../Home'
import Login from '../Login'
import Empty from 'narrative/Empty'
import PageRender from 'narrative/PageRender'
import Narrative from 'narrative/Narrative'
import history from './MainRouterHistory'
import { TransitionGroup, CSSTransition ,SwitchTransition} from "react-transition-group";
import Globals from 'system/Globals';
import gsap,{Power3} from 'gsap';
import Denied from '../Denied'
import ControllerNarrativeMain from '../../controller/ControllerNarrativeMain';
import RemoteComs from 'system/RemoteComs'
import io from 'socket.io-client';
let socket;


if (!Globals.instance().controllerApp && !Globals.instance().serverApp) {
    socket = io(Globals.instance().socketURL)
}


const SystemRoutes = [
   
    {
        'path':Globals.instance().getRoute('/landing'),
        'key':'landing',
        'component':Landing
    },
    // {
    //     'path':Globals.instance().getRoute('/join'),
    //     'key':'join',
    //     'component':RemoteComs
    // },
    {
        'path':Globals.instance().getRoute('/login'),
        'key':'login',
        'component':Login
    },
    {
        'path':Globals.instance().getRoute('/denied'),
        'key':'login',
        'component':Denied
    },
    {
        'path':Globals.instance().getRoute('/home/myproperties'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/settings'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/search'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/:d1/:d2/:d3/:d4'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/:d1/:d2/:d3'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home/:d1/:d2'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home/:d1'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/controllernarrative/:id?'),
        'key':'controllernarrative',
        'component':ControllerNarrativeMain
    },
    {
        'path':Globals.instance().getRoute('/render/narrative/:id/:pageid'),
        'key':'narrative-page',
        'component':PageRender
    },
    {
        'path':Globals.instance().getRoute('/narrative/:id?'),
        'key':'narrative',
        'component':Empty
    },
    {
        'path':Globals.instance().getRoute('/redirect'),
        'key':'launch',
        'component':Launch
    },
    {
        'path':Globals.instance().getRoute('/'),
        'key':'launch',
        'component':Launch
    }
   
]




const MainRouter =function(props){



    const [socketPrefs, setSocketPrefs] = useState({'mode': null, 'room': null, 'clientid': null});
    const [showNarrative,setShowNarrative] = useState(undefined)
    const [showWelcome, setShowWelcome] = useState();

    const narrativeKey=useRef()
    const refSection =useRef()
    useEffect(()=>{
        history.listen(()=>{
            let params = new URLSearchParams(history.location.search)
            
            let narrative = params.get('narrative')

            // console.log('MainRouter history listener 1', narrative)

            if(history.location.pathname.indexOf('/narrative')===0 )
            narrative = history.location.pathname.split("/")[2]
            narrativeKey.current=narrative

            // console.log('MainRouter history listener 2', narrative, narrativeKey.current)

            if( narrative){
                 setShowNarrative(narrative)
            }
            else{
                if (refSection.current) {
                    let eleNar=refSection.current.querySelector('.narrative-holder')
                    if(eleNar){
                        eleNar.style['pointer-events']='none'
                        gsap.to(eleNar,{duration:0.75, opacity:0,ease:Power3.easeInOut,onComplete:()=>{
                            setShowNarrative(undefined)
                        }})
                    }else{
                        setShowNarrative(undefined)
                    }
                } else {
                    setShowNarrative(undefined)
                }
               
                
            }
        })
        
    },[])



    function transitionKey(loc){
        if(loc.pathname.indexOf('/home')===0)    
            return 'home'
        else if(loc.pathname.indexOf('/narrative')===0)
            return loc.pathname
        else 
            return loc.key
    } 

    function fadeTimeout(loc){
        if(Globals.instance().controllerApp)
            return { enter: 450, exit: 450}
        else
            return { enter: 0, exit: 0}
    }
    function fadeClass(loc){
        // console.log(loc)
        // if(loc.pathname.indexOf('/narrative')===0)
        //     return ''
        // else 
            return 'fade-page'
    }
  
    function renderNarrativeForLoad(location){
        let params = new URLSearchParams(location.search)
        let narrative = params.get('narrative')

        console.log('renderNarrativeForLoad', location, location.search, narrative)
        if(narrative){
            return 
                              
        }
    }

    function checkReRoute(location,init,user){
        // let reroute= false
        console.log('checkReRoute', location, init, user)
        if (location && location.pathname && (location.pathname.indexOf('/C:') > -1)) {
            location.pathname = location.pathname.replace('/C:', '')
            console.log('has c:/', location.pathname)
            
        }
        
        if(location && location.pathname!=='/' && location.pathname.indexOf('/admin')===0) {
            // console.log(Globals.instance().adminAPI)
            return  <Redirect to={{ path:Globals.instance().adminAPI}} />
        }
        
        if(!init && location 
            && location.pathname !=='/' 
            && location 
            && location.pathname.indexOf('/render/')!==0 
            && location.pathname.indexOf('/redirect')!==0 
            && location.pathname.indexOf('/denied')!==0 
            && location.pathname.indexOf('/login')!==0 ) {
                console.log('redirect by referrer', history.location )
            return  <Redirect  to={{ pathname:'/', state: { referrer: history.location }}} />
        }
        
        if((location.pathname.indexOf("/narrative/")===0 || location.pathname.indexOf("/controllernarrative/")===0) && !(Globals.instance().mode === 'static-web') ){
            // if(!user)return <Redirect to={{ pathname:'/login', state: { referrer: history.location }}} />
            let arr = location.pathname.split("/")
            let nid = arr[arr.length-1]
            if(Number(nid)!==Globals.instance().narrativeHelp && user.narratives.findIndex(i=>{return String(i)===String(nid)}) === -1) 
                return <Redirect to={{ pathname:'/denied' }} />
        } 

            // let strSync=sessionStorage.getItem('setpref-socket');
            // console.log('nav', location, location.pathname)
            // if ((strSync && strSync['mode'] === 'isBroadcast')||(socketPrefs && socketPrefs.mode === "isBroadcast")) {
              
            //         socket.emit('cast', [socketPrefs.room, 'nav#path=' + location.pathname + location.search]);

            // }
    

       
    }

    function getComponentKey(route,location){
        let arrLoc=location.pathname.split("/")
        let key=route.key

        
        if(arrLoc.length>2 && arrLoc[1]==='narrative')key= route.key+'/'+arrLoc[2]
        return key
    }

  
    return (
        
        <section className={'fullscreen'} ref={refSection}>

        {socket && <RemoteComs {...props} socket={socket} socketPrefs={socketPrefs} setSocketPrefs={setSocketPrefs} history={history} setShowWelcome={setShowWelcome} showWelcome={showWelcome} />}

            <Router history={history} basename={Globals.instance().basepath} >
            

          
            <Route render={({ location }) => (
                
                <TransitionGroup  style={ {
                    position: 'absolute',
                    top:0,
                    left:0,
                    width:'100%',
                    height:'100%',
                    zIndex:0
                  }}>
                
                     <CSSTransition
                     timeout={fadeTimeout(location)}
                     classNames={fadeClass(location)}
                     key={transitionKey(location)}
                    > 
            
                
                    <Switch location={location}>
                        {
                        checkReRoute(history.location, props.isInitialized,props.user) 
                        }
                       
                        {
                            SystemRoutes.map((route, i) => (
                                <Route
                                path={route.path}
                                key={('route'+getComponentKey(route,location))}
                                render={
                                    props => {
                                        return (
                                        <React.Fragment>
                          
                                            <route.component {...props} user={props.user} socketPrefs={socketPrefs} setSocketPrefs={setSocketPrefs} socket={socket} setShowWelcome={setShowWelcome} routes={route.routes} key={getComponentKey(route,location)} />
                                            {
                                                location.pathname.indexOf('/home'===0) && (
                                                renderNarrativeForLoad(location)
                                                )
                                            }
                                        </React.Fragment>
                                )}}
                                />

                            ))
                            
                        }
                       
                    </Switch>

                    
            
                </CSSTransition>
                </TransitionGroup>
            )} />
                    
            </Router>
            {
                showNarrative && 
                <Narrative history={history} nid={showNarrative} setShowWelcome={setShowWelcome} socketPrefs={socketPrefs} setSocketPrefs={setSocketPrefs} socket={socket}  key={('componentnarrative'+narrativeKey.current+socketPrefs.mode)}  />
            }
        </section>
    )
}



  
const mapStateToProps = state => {
    const {intialized,user} = state
    return { 
        isInitialized:intialized,
        user
        };
};



export default connect(mapStateToProps, null)(MainRouter)

// TEST: Turn back on
// 